import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { lazy, Suspense, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Provider } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import api from './entities/api';
import { store } from './entities/store';
import ROUTES from './layout/routes';
import { ParkingPage } from './pages/parking-page/ParkingPage';
import { ServerErrorPage } from './pages/server-error-page/ServerErrorPage';
import VisualBulkPage from './pages/visual-page/visual-bulk-page/VisualBulkPage';
import WhiteBoxPage from './pages/white-box-page/WhiteBoxPage';
import LoaderCircle from './shared/loader-circle/LoaderCircle';
import useBitrixChatFormEffect from './tools/hooks/useBitrixChatFormEffect';

const Layout = lazy(() => import('./layout/Layout'));
const LayoutMobile = lazy(() => import('./layout/LayoutMobile'));
const ContactsPage = lazy(() => import('./pages/contacts-page/ContactsPage'));
const DocumentsPage = lazy(() => import('./pages/documents-page/DocumentsPage'));
const FavoriteFlatsPage = lazy(() => import('./pages/favorite-flats-page/FavoriteFlatsPage'));
const FlatPage = lazy(() => import('./pages/flat-page/FlatPage'));
const FlatsPage = lazy(() => import('./pages/flats-page/FlatsPage'));
const GeneralPage = lazy(() => import('./pages/general-page/GeneralPage'));
const NewsPage = lazy(() => import('./pages/news-page/NewsPage'));
const NotFoundPage = lazy(() => import('./pages/not-found-page/NotFoundPage'));
const PrivacyPage = lazy(() => import('./pages/privacy-page/PrivacyPage'));
const CashbackPage = lazy(() => import('./pages/cashback-page/CashbackPage'));
const ProgressOnePage = lazy(() => import('./pages/progress-one-page/ProgressOnePage'));
const ProgressPage = lazy(() => import('./pages/progress-page/ProgressPage'));
const PromoPage = lazy(() => import('./pages/promo-page/PromoPage'));
const TeamPage = lazy(() => import('./pages/team-page/TeamPage'));
const TermsPage = lazy(() => import('./pages/terms-page/TermsPage'));
const AboutPage = lazy(() => import('./pages/about-page/AboutPage')); // FORMA-3517
const LandscapingPage = lazy(() => import('./pages/landscaping-page/LandscapingPage'));
const LocationPage = lazy(() => import('./pages/location-page/LocationPage'));
const InfrastucturePage = lazy(() => import('./pages/infrastructure-page/InfrastructurePage'));
const CommercialPage = lazy(() => import('./pages/commercial-page/СommercialPage'));
const GalleryPage = lazy(() => import('./pages/gallery-page/GalleryPage'));
const VisualRootPage = lazy(() => import('./pages/visual-page/visual-root-page/VisualRootPage'));
const VisualFloorPage = lazy(() => import('./pages/visual-page/visual-floor-page/VisualFloorPage'));
const VisualFlatPage = lazy(() => import('./pages/visual-page/visual-flat-page/VisualFlatPage'));
const BookingPage = lazy(() => import('./pages/booking-page/BookingPage'));
const SiteMapPage = lazy(() => import('./pages/site-map/SiteMapPage'));
const SchoolPage = lazy(() => import('./pages/school-page/SchoolPage'));

const handleChangeWidth = (width, setWidth) => {
  const delta = width - window.innerWidth;
  if (delta > 100 || delta < 0) {
    setWidth(window.innerWidth);
  }
  return null;
};

export const client = new ApolloClient({
  uri: api.urlDvizh,
  cache: new InMemoryCache(),
});

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [loaded, setLoaded] = useState(false);
  const { pathname } = useLocation();

  useBitrixChatFormEffect();

  useEffect(() => {
    if (!pathname.includes('progress')) {
      window.addEventListener('resize', handleChangeWidth(width, setWidth));
      window.addEventListener('orientationchange', handleChangeWidth(width, setWidth));
    }

    setUserAgent(navigator.userAgent);
    if (width < 640) {
      setLoaded(true);
    } else
      setTimeout(() => {
        setLoaded(true);
      }, 2000);
  }, []);

  //Перезагрузка страницы при возвращении на неё кнопкой "Назад" в браузере

  const loc = window.location;
  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }, [loc.pathname]);

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Routes>
          {!isMobileOnly && (
            <Route
              path={ROUTES.root}
              element={
                <Suspense fallback={<LoaderCircle />}>
                  <Layout width={width} userAgent={userAgent} loaded={loaded} setLoaded={setLoaded} />
                </Suspense>
              }
            >
              <Route
                path={ROUTES.flats.flat}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <FlatPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.favorites.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <FavoriteFlatsPage />
                  </Suspense>
                }
              />
              <Route
                index
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <GeneralPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.privacy}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PrivacyPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.cashback}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <CashbackPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.contacts}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <ContactsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.team}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <TeamPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.documents}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <DocumentsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.purchaseTerms.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <TermsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.news.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <NewsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.news.slug}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <NewsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.promo.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PromoPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.promo.slug}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PromoPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.progress.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <ProgressPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.landscaping}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <LandscapingPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.about}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <AboutPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.location}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <LocationPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.infrastructure}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <InfrastucturePage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.commercial}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <CommercialPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualRootPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.house}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualBulkPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.bulk}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualFloorPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.floor}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualFlatPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.whitebox}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <WhiteBoxPage />
                  </Suspense>
                }
              />
              <Route path={ROUTES.booking} element={<BookingPage />} />
              <Route path={ROUTES.map} element={<SiteMapPage />} />
              {process.env.REACT_APP_FORMA_13482 === 'true' && (
                <Route path={ROUTES.parking} element={<ParkingPage />} />
              )}
              <Route path={ROUTES.serverError} element={<ServerErrorPage />} />
              <Route
                path={ROUTES.school}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <SchoolPage />
                  </Suspense>
                }
              />
            </Route>
          )}
          {isMobileOnly && (
            <Route
              path={ROUTES.root}
              element={
                <Suspense fallback={<LoaderCircle />}>
                  <LayoutMobile width={width} userAgent={userAgent} loaded={loaded} setLoaded={setLoaded} />
                </Suspense>
              }
            >
              <Route
                path={ROUTES.flats.flat}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <FlatPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.favorites.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <FavoriteFlatsPage />
                  </Suspense>
                }
              />
              <Route
                index
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <GeneralPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.privacy}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PrivacyPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.cashback}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <CashbackPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.contacts}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <ContactsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.team}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <TeamPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.documents}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <DocumentsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.purchaseTerms.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <TermsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.news.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <NewsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.news.slug}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <NewsPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.promo.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PromoPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.promo.slug}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <PromoPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.progress.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <ProgressPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.about}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <AboutPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.location}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <LocationPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.landscaping}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <LandscapingPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.infrastructure}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <InfrastucturePage />
                  </Suspense>
                }
              />

              <Route
                path={ROUTES.flats.visual.root}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualRootPage />
                  </Suspense>
                }
              />

              <Route
                path={ROUTES.flats.visual.house}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualBulkPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.bulk}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualFloorPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.flats.visual.floor}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <VisualFlatPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.whitebox}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <WhiteBoxPage />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.commercial}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <CommercialPage />
                  </Suspense>
                }
              />
              <Route path={ROUTES.booking} element={<BookingPage />} />
              <Route path={ROUTES.map} element={<SiteMapPage />} />
              {process.env.REACT_APP_FORMA_13482 === 'true' && (
                <Route path={ROUTES.parking} element={<ParkingPage />} />
              )}
              <Route path={ROUTES.serverError} element={<ServerErrorPage />} />
              <Route
                path={ROUTES.school}
                element={
                  <Suspense fallback={<LoaderCircle />}>
                    <SchoolPage />
                  </Suspense>
                }
              />
            </Route>
          )}

          <Route
            path={ROUTES.flats.list}
            element={
              <Suspense fallback={<LoaderCircle />}>
                <FlatsPage width={width} userAgent={userAgent} loaded={loaded} setLoaded={setLoaded} />
              </Suspense>
            }
          />
          <Route
            path='*'
            element={
              <Suspense fallback={<LoaderCircle />}>
                <NotFoundPage width={width} userAgent={userAgent} loaded={loaded} setLoaded={setLoaded} />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.progress.slug}
            element={
              <Suspense fallback={<LoaderCircle />}>
                <ProgressOnePage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.gallery}
            element={
              <Suspense fallback={<LoaderCircle />}>
                <GalleryPage />
              </Suspense>
            }
          />
        </Routes>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
