import ScrollHelperBlackTrans from '../../tools/helpers/ScrollHelperBlackLocation';
import style from './ServerErrorPage.module.scss';

export const ServerErrorPage = () => {
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.content}>
          <div className={style.content__title}>Ошибка 502</div>
          <div className={style.content__description}>Упс, сервер не доступен, пожалуйста, перезагрузите страницу.</div>
          <button className={style.content__reloadButton} onClick={() => window.location.reload()}>
            Перезагрузить
          </button>
        </div>
      </div>
      <ScrollHelperBlackTrans />
    </>
  );
};
