import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchVisual = createAsyncThunk('visualSlice/fetchVisual', async (number, thunkApi) => {
  return fetchData(api.urlFlats, thunkApi);
});

const initialState = {
  visual: null,
  fetchError: false,
  activeQueue: null,
};

export const visualSlice = createSlice({
  name: 'visualSlice',
  initialState,
  reducers: {
    handleChangeActiveQueue: (state, action) => {
      state.activeQueue = action.payload;
    },
  },
  extraReducers: {
    [fetchVisual.pending]: (state, action) => {},
    [fetchVisual.fulfilled]: (state, action) => {
      state.visual = action.payload;
    },
    [fetchVisual.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { handleChangeActiveQueue } = visualSlice.actions;

export const visualSelector = state => state.visual.visual;
export const fetchErrorSelector = state => state.visual.fetchError;
export const activeQueueSelector = state => state.visual.activeQueue;

export default visualSlice.reducer;
