import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';

export const fetchFlatInformation = createAsyncThunk(
  'flatsSlice/fetchFlatInformation',
  async ({ flatId }, thunkApi) => {
    const url = `${process.env.REACT_APP_MANAGER_URL}/api/v2/flat/${flatId}?blockId=1457`;
    return fetchData(url, thunkApi);
  },
);

const initialState = {
  flat: {},
  flatConfigure: [
    {
      id: 1,
      name: 'С мебелью',
      characteristic: 'withFurniture',
    },
    {
      id: 2,
      name: 'С размерами',
      characteristic: 'withSize',
    },
    {
      id: 3,
      name: 'На этаже',
      characteristic: 'onFloor',
    },
    {
      id: 4,
      name: 'На генплане',
      characteristic: 'onGenplan',
    },
  ],
  flatDisplays: {
    flatContent: null,
    flatChoices: [],
  },
  flatGenPlan: false,
  flatInfo: null,
  flatDimensions: false,
  error: false,
};

export const flatSlice = createSlice({
  name: 'flat',
  initialState,
  reducers: {
    setFlat: (state, action) => {
      state.flat = action.payload;
    },
    setFlatImg: (state, action) => {
      state.flatDisplays.flatChoices = [...action.payload];
    },
    setFlatLoading: (state, action) => {
      state.flatDisplays.flatContent = {
        id: action.payload.id,
        image: action.payload.image,
        active: action.payload.type,
      };
    },
    setDisplayFlat: (state, action) => {
      switch (action.payload) {
        case 'onFloor':
          const itemFloor = state?.flatDisplays?.flatChoices?.filter(el => {
            return el.characteristic === 'onFloor';
          })[0]?.image;
          const itemFloorPng = state?.flatDisplays?.flatChoices?.filter(el => {
            return el.characteristic === 'onFloor';
          })[0]?.imagePng;
          state.flatDisplays.flatContent = {
            id: 2,
            image: itemFloor,
            imagePng: itemFloorPng,
            active: 'onFloor',
          };
          state.flatGenPlan = false;
          break;
        case 'withSize':
          const itemSize = state?.flatDisplays?.flatChoices?.filter(el => {
            return el.characteristic === 'withSize';
          })[0]?.image;

          state.flatDisplays.flatContent = {
            id: 3,
            image: itemSize,
            active: 'withSize',
          };
          state.flatGenPlan = false;
          break;
        case 'onGenplan':
          const itemgenPlan = state?.flatDisplays?.flatChoices?.filter(el => {
            return el.characteristic === 'onGenplan';
          })[0]?.image;
          state.flatDisplays.flatContent = {
            id: 4,
            image: itemgenPlan,
            active: 'onGenplan',
          };
          state.flatGenPlan = true;
          break;
        default:
          {
            const itemSize = state?.flatDisplays?.flatChoices?.filter(el => {
              return el.characteristic === 'withFurniture';
            })[0]?.image;

            state.flatDisplays.flatContent = {
              id: 1,
              image: itemSize,
              active: 'withFurniture',
            };
            state.flatGenPlan = false;
          }
          break;
      }
    },
    cleanFlatInfo: state => {
      state.flatInfo = null;
    },
    setGenPlan: (state, action) => {
      state.flatGenPlan = action.payload;
    },
    setFlatDimensions: (state, action) => {
      state.flatDimensions = action.payload;
    },
    cleanError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [fetchFlatInformation.pending]: (state, action) => {},
    [fetchFlatInformation.fulfilled]: (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.error = true;
      } else {
        state.flatInfo = action.payload;
      }
    },
    [fetchFlatInformation.rejected]: (state, action) => {
      console.log('Ошибка загрузки квартиры');
    },
  },
});

export const {
  setFlatLoading,
  setDisplayFlat,
  setFlatImg,
  setFlat,
  cleanFlatInfo,
  setGenPlan,
  setFlatDimensions,
  cleanError,
} = flatSlice.actions;

export default flatSlice.reducer;
