import style from './SliderHand.module.scss';

const SliderHand = props => {
  return (
    <div
      className={
        props.orientation === 'right' ? style.sliderHand + ' ' + style.__right : style.sliderHand + ' ' + style.__left
      }
    >
      <div className={style.sliderHandWrapper + ' ' + style.sliderHandWrapper_visible}>
        <img
          src='https://static.tildacdn.com/tild3834-6331-4462-b361-316363316363/hand.svg'
          className={style.sliderHand__hand}
          alt='Указатель слайдера'
          width='100%'
          height='auto'
        />
      </div>
    </div>
  );
};

export default SliderHand;
