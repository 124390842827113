import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import style from './Tab.module.scss';

const Tab = ({ text, link, mobTabActiveStyle, mobTabStyle, mobTabBehindStyle, disabled, hide, isActive, isBehind }) => {
  const navigate = useNavigate();

  if (hide) return;

  const getStyles = () => {
    if (isActive) return cn(style.tab__active, mobTabActiveStyle);
    if (!isActive && !isBehind) return cn(style.tab, mobTabStyle);
    if (!isActive && isBehind) return cn(style.tab__behind, mobTabBehindStyle);
  };

  return (
    <button className={getStyles()} disabled={disabled} onClick={() => navigate(link)}>
      {text}
    </button>
  );
};

export default Tab;
