import { Link, useLocation } from 'react-router-dom';
import icon from '../../../../assets/images/visual_images/linkArrow.svg';
import ROUTES from '../../../../layout/routes';
import style from './ListLink.module.scss';

function ListLink() {
  const { pathname } = useLocation();

  const getLocation = () => {
    return pathname === ROUTES.flats.visual.root;
  };

  return (
    <>
      {getLocation() ? (
        <Link to={ROUTES.flats.list} className={style.listLink}>
          <span>Выбор по параметрам</span>
          <img src={icon} alt='link-arrow' />
        </Link>
      ) : (
        <></>
      )}
    </>
  );
}

export default ListLink;
