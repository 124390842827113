import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchGallery = createAsyncThunk('gallerySlice/fetchGallery', async (_, thunkApi) => {
  return fetchData(api.urlGallery, thunkApi);
});

const initialState = {
  gallery: null,
  fetchError: false,
};

export const gallerySlice = createSlice({
  name: 'gallerySlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGallery.fulfilled]: (state, action) => {
      state.gallery = action.payload;
    },
    [fetchGallery.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const gallerySelector = state => state.galleryInfo.gallery;
export const fetchErrorSelector = state => state.galleryInfo.fetchError;

export default gallerySlice.reducer;
