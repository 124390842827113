import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchVisualBulk = createAsyncThunk('visualBulkSlice/fetchVisualBulk', async thunkApi => {
  return fetchData(api.urlFlats, thunkApi);
});

const initialState = {
  data: null,
  fetchError: false,
  activeBulk: null,
  bulkLock: false,
};

export const visualBulkSlice = createSlice({
  name: 'visualBulkSlice',
  initialState,
  reducers: {
    setActiveBulk: (state, action) => {
      state.activeBulk = action.payload;
    },
    setBulkLock: (state, action) => {
      state.bulkLock = action.payload;
    },
  },
  extraReducers: {
    [fetchVisualBulk.pending]: (state, action) => {
      console.log('квартира загружается');
    },
    [fetchVisualBulk.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [fetchVisualBulk.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { setActiveBulk, setBulkLock } = visualBulkSlice.actions;

export const dataSelector = state => state.visualBulk.data;
export const fetchErrorSelector = state => state.visualBulk.fetchError;
export const activeBulkSelector = state => state.visualBulk.activeBulk;
export const bulkLockSelecetor = state => state.visualBulk.bulkLock;

export default visualBulkSlice.reducer;
