import classNames from 'classnames';
import { useState } from 'react';
import cross from '../../assets/images/architecture_images/cross.svg';
import whiteBox from '../../assets/images/architecture_images/whiteBox.jpg';
import whiteBoxWeb from '../../assets/images/architecture_images/whiteBox.webp';
import Image from '../../shared/image/Image';
import { hideData, visibleData } from './dataWhiteBox';
import MoreButton from './more-button/MoreButton';
import style from './WhiteBox.module.scss';

function WhiteBox({ show = false }) {
  const [toggle, setToggle] = useState(false);

  const styles = toggle ? style.thirdScreenOpen : style.thirdScreen;

  const buttonText = toggle ? 'Скрыть' : 'Показать ещё';

  return (
    <div className={styles}>
      <div className={style.textBox}>
        <p className={style.header}>
          Самый
          <br /> спокойный
          <br /> переезд
        </p>
        <p className={style.text}>
          Все квартиры {!show && 'в башне 1-ой очереди'} сдаются с отделкой White Box. С момента покупки квартиры больше
          хочется наслаждаться тишиной и пением птиц, чем строительной пылью и трелью перфоратора.
          <br /> <br />
          Черновые работы останутся позади, а вам останется самая увлекательная часть — выбрать финишные материалы и
          декорировать ваш будущий дом по своему вкусу.
        </p>
      </div>
      <div className={style.bgBox}>
        <Image className={style.bg} src={whiteBox} srcWebP={whiteBoxWeb} name='White box' imagePlug={whiteBox} />
        <p className={style.bgDescription}>Отделка White Box</p>
      </div>

      <div className={style.cardsSection}>
        <p className={style.subtitle}>В отделку включено</p>
        <div className={style.cardBox}>
          {visibleData.map(item => {
            return (
              <div key={item.id} className={style.card}>
                <img className={style.icon} src={item.icon} alt='icon' />
                <p className={style.description}>{item.description}</p>
              </div>
            );
          })}
          <img className={classNames(style.crosses, style.cross1)} src={cross} alt='В отделку включено' />
          <img className={classNames(style.crosses, style.cross2)} src={cross} alt='В отделку включено' />
          <img className={classNames(style.crosses, style.cross3)} src={cross} alt='В отделку включено' />
        </div>
      </div>

      {!show && (
        <>
          {toggle && (
            <div className={style.hiddenSection} id='hidden'>
              <div className={style.hiddenSectionLeft}>
                {hideData.slice(0, 4).map(item => {
                  return (
                    <div key={item.id} className={style.hiddenCard}>
                      <div className={style.hiddenNumber}>{item.id}</div>
                      <p className={style.hiddenDescription}>{item.description}</p>
                    </div>
                  );
                })}
              </div>
              <div className={style.hiddenSectionRight}>
                {hideData.slice(4).map(item => {
                  return (
                    <div key={item.id} className={style.hiddenCard}>
                      <div className={style.hiddenNumber}>{item.id}</div>
                      <p className={style.hiddenDescription}>{item.description}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className={style.buttonBox}>
            <MoreButton
              buttonText={buttonText}
              toggleHandler={() => {
                setToggle(!toggle);
              }}
            />
          </div>
        </>
      )}

      {show && (
        <div className={style.hiddenSection} id='hidden'>
          <div className={style.hiddenSectionLeft}>
            {hideData.slice(0, 4).map(item => {
              return (
                <div key={item.id} className={style.hiddenCard}>
                  <div className={style.hiddenNumber}>{item.id}</div>
                  <p className={style.hiddenDescription}>{item.description}</p>
                </div>
              );
            })}
          </div>
          <div className={style.hiddenSectionRight}>
            {hideData.slice(4).map(item => {
              return (
                <div key={item.id} className={style.hiddenCard}>
                  <div className={style.hiddenNumber}>{item.id}</div>
                  <p className={style.hiddenDescription}>{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default WhiteBox;
