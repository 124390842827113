import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import booleanSwitch from '../tools/_boolean-switch';
import calcTheLargest from '../tools/_calc-the-largest';
import calcTheLeast from '../tools/_calc-the-least';
import changeStudioValue from '../tools/_change-studio-value';
import checkParameterAndToggleStatus from '../tools/_check-parameter-and-toggle-status';
import editPriceInMillions from '../tools/_edit-price-in-millions';
import { fetchData } from '../tools/_fetch-data-helper';
import {
  applyOneParamFilter,
  applyThreeParamsFilter,
  applyTwoParamsFilter,
  countSelectedParams,
  createCheckboxFilters,
  createFindCheckboxFunctions,
  disableUnselectedCheckboxes,
  filterBySliders,
  getActionType,
  getChosenParams,
  handleQuarterOrDeadline,
  handleSlider,
  setSlidersValues,
  sortFlatsHelper,
  updateCheckboxes,
} from '../tools/_filter_slice_helpers';
import getSelectedParams from '../tools/_get-selected-params';
import resetSortingParameter from '../tools/_reset-sorting-parameter';
import sortNumber from '../tools/_sort-number';
import api from './api';

export const fetchFlats = createAsyncThunk(
  'flatsSlice/fetchFlats',
  async ({ withProcessing = true } = {}, thunkApi) => {
    const data = await fetchData(api.urlFlats, thunkApi);
    return { data, withProcessing };
  },
);

export const fetchFlat = createAsyncThunk('flatsSlice/fetchFlat', async (number, thunkApi) => {
  const url = `${process.env.REACT_APP_MANAGER_URL}/api/v2/flat/${number}?blockId=1457`;
  return fetchData(url, thunkApi);
});

export const fetchAttributes = createAsyncThunk('flatsSlice/fetchAttributes', async (_, thunkApi) => {
  const response = await fetchData(api.urlAttributes, thunkApi);
  return response.data;
});

const initialState = {
  urls: {
    getResidential: {
      url: api.urlFlats,
      initial: false,
      status: null,
      error: null,
    },
  },
  filterParameters: {
    byCheckbox: {
      redPrice: [{ name: 'Квартира по акции', value: true, active: false }],
      rooms: [
        { name: 'Любоe', value: 'Любой', active: false, disabled: false },
        { name: 'СТ', value: '0', active: false, disabled: false },
        { name: '1', value: '1', active: false, disabled: false },
        { name: '2', value: '2', active: false, disabled: false },
        { name: '3', value: '3', active: false, disabled: false },
        { name: '4', value: '4', active: false, disabled: false },
        { name: '5', value: '5', active: false, disabled: false },
      ],
      sections: null,
      deadline: [
        { name: 'Любой', value: 'Любой', active: false },
        { name: 'III кв 2025', value: 'III кв 2025', active: false },
        { name: 'IV кв 2025', value: 'IV кв 2025', active: false },
        { name: 'I кв 2028', value: 'I кв 2028', active: false },
      ],
      quarter: [
        { name: 'Любой', value: 'Любой', active: false },
        {
          name: 'Квартал Некрасовка',
          value: 'Квартал Некрасовка',
          active: false,
        },
      ],
      advantages: null,
    },
    selectedParams: {},

    //Значения ползунков в фильтре по умолчанию
    initialValues: {
      area: [0, 10],
      price: [0, 10],
      floor: [0, 10],
    },
    //Возможные параметры фильтрации
    params: ['floor', 'price', 'area'],
    //Параметры, которые были выбраны пользователем
    interactedParams: {
      floor: {
        min: false,
        max: false,
      },
      price: {
        min: false,
        max: false,
      },
      area: {
        min: false,
        max: false,
      },
    },
    //Выбранные параметры для отображения на странице в кнопках сброса
    ableToResetParams: {
      sections: [],
      rooms: [],
      floor: [],
      area: [],
      price: [],
      advantages: [],
    },
    inactiveSliders: {
      floor: false,
      price: false,
      area: false,
    },
    //Текущие значения слайдеров
    slidersValues: {
      area: [25, 163],
      price: [12000000, 86000000],
      floor: [1, 30],
    },
    //Крайние значения слайдеров
    slidersExtremeValues: {
      area: [25, 163],
      price: [1200000, 86000000],
      floor: [1, 30],
    },
    //Значения инпутов
    inputsValues: {
      area: [25, 163],
      price: [12000000, 86000000],
      floor: [1, 30],
    },
    amountOfSelectedParams: 0,
  },
  //Колонки таблицы для сортировки
  sortColumns: {
    scheme: 'Планировка',
    rooms: 'Cпален',
    number: 'Номер',
    area: 'Площадь',
    sections_number: 'Корпус',
    floor: 'Этаж',
    fav: 'Срок сдачи',
    real_price: 'Cтоимость',
    advantages: 'Особенности',
  },
  selectedСategories: [],
  //Текущий параметр сортировки
  sortParameters: {
    value: 'area',
    direction: 'asc_',
  },
  //Места вызова функции
  //по клику на заголовок столбца таблицы
  inTableHead: 'inTableHead',
  //после изменения состояния формы
  inForm: 'inForm',
  //Квартиры, которые выводятся на страницу
  flat: {},
  flatInfo: {},
  flatsData: null,
  flats: [],
  //Все квартиры, взятые с сервера
  allFlats: [],
  maxFloor: '',
  //Квартиры после фильтрации чекбоксами
  filteredByCheckboxFlats: [],
  //Отфильтрован список квартир?
  isFiltered: false,
  countFilteres: [],
  //Выбранная квартира
  currentFlat: '',
  apartmentNumber: '',
  //Статус квартиры
  flatStatus: {
    free: 'free',
    reserve: 'reserve',
  },
  //Список специальных условий
  benefits: [],
  //Активное спец.условие
  activeBenefit: {},
  filtersSelect: {
    selectAmountRoom: 'Любой',
    selectFrame: 'Любой',
    selectRent: 'Любой',
    selectRoom: 'Любоe',
  },
  toggleMenu: [
    { id: 1223, img: '#burger', isActive: true, visible: 'list' },
    { id: 2231, img: '#menu', isActive: false, visible: 'square' },
  ],
  filterSelect: {
    cellContent: {
      id: 3,
      title: 'Площадь по возрастанию',
      active: true,
    },
    choices: [
      { id: 1, title: 'Цена по возрастанию', active: false },
      {
        id: 2,
        title: 'Цена по убыванию',
        active: false,
      },
      {
        id: 3,
        title: 'Площадь по возрастанию',
        active: true,
      },
      {
        id: 4,
        title: 'Площадь по убыванию',
        active: false,
      },
    ],
  },
  list: 'list',
  redFlats: false,
  toggleRedFlats: false,
  installment: false,
  rare: false,
  seoData: null,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterName: (state, { payload }) => {
      switch (payload.name) {
        case 'selectAmountRoom':
          state.filtersSelect.selectAmountRoom = payload.desc;
          break;
        case 'selectFrame':
          state.filtersSelect.selectFrame = payload.desc;
          break;
        case 'selectRent':
          state.filtersSelect.selectRent = payload.desc;
          break;
        case 'selectRoom':
          state.filtersSelect.selectRoom = payload.desc;
          break;
        default:
          break;
      }
    },
    setToggleMenu: (state, { payload }) => {
      if (state.list !== payload.visible) {
        state.list = payload.visible;
        const newMenu = state.toggleMenu.map(el => {
          return el.id === payload.id ? { ...el, isActive: !el.isActive } : { ...el, isActive: false };
        });
        state.toggleMenu = [...newMenu];
      }
    },
    updateTableSorts(state, action) {
      const param = action.payload === 'price' ? 'real_price' : action.payload;
      if (!state.selectedСategories.includes(param)) {
        state.selectedСategories = [...state.selectedСategories, param];
      }
    },
    //Получение минимальных и максимальных параметров для фильтрации по range-слайдерам
    setExtremeSlidersValues(state) {
      const { params, initialValues, interactedParams, slidersExtremeValues, slidersValues, inputsValues } =
        state.filterParameters;

      const setDefaultValues = param => {
        const [minParam, maxParam] = initialValues[param];
        slidersExtremeValues[param] = [minParam, maxParam];
        slidersValues[param] = [minParam, maxParam];
        inputsValues[param] = [minParam, maxParam];
      };

      const calculateValues = param => {
        const largestValue = calcTheLargest(state.flats, param);
        const leastValue = calcTheLeast(state.flats, param, largestValue);
        const maxParam = param !== 'price' ? Math.ceil(largestValue) : Math.ceil(largestValue / 1000000) * 1000000;
        const minParam = param !== 'price' ? Math.floor(leastValue) : Math.floor(leastValue / 1000000) * 1000000;
        return { minParam, maxParam };
      };

      const updateSliderValues = (param, minParam, maxParam) => {
        if (!interactedParams[param].min) {
          slidersValues[param][0] = minParam;
          inputsValues[param][0] = minParam;
          slidersExtremeValues[param][0] = minParam;
        }
        if (!interactedParams[param].max) {
          slidersValues[param][1] = maxParam;
          inputsValues[param][1] = maxParam;
          slidersExtremeValues[param][1] = maxParam;
        }
      };

      params.forEach(param => {
        if (state.allFlats.length === 0) {
          setDefaultValues(param);
          return;
        }

        const { minParam, maxParam } = calculateValues(param);
        const isSmallRange = maxParam - minParam <= (param === 'price' ? 1000000 : 1);

        if (isSmallRange && minParam !== 0) {
          if (!interactedParams[param].min && !interactedParams[param].max) {
            setDefaultValues(param);
            state.filterParameters.inactiveSliders[param] = true;
          }
        } else if (minParam !== 0 && minParam !== maxParam) {
          state.filterParameters.inactiveSliders[param] = false;
          updateSliderValues(param, minParam, maxParam);
        } else if (
          minParam === 0 &&
          minParam === maxParam &&
          !interactedParams[param].min &&
          !interactedParams[param].max
        ) {
          const [lastMin, lastMax] = slidersExtremeValues[param];
          slidersValues[param] = [lastMin, lastMax];
          inputsValues[param] = [lastMin, lastMax];
          state.filterParameters.inactiveSliders[param] = true;
        }
      });
    },

    checkRelevantAttributes(state) {
      // Если advantages ещё не загрузились (равны null), выходим
      if (!state?.filterParameters?.byCheckbox?.advantages) return;

      // Создаем Set для непустых характеристик
      const nonEmptyAdvantages = new Set();

      // Проходим по каждой квартире и собираем значения характеристик
      for (let i = 0; i < state.allFlats.length; i++) {
        const flat = state.allFlats[i];
        Object.keys(flat?.attributes).forEach(key => {
          if (flat.attributes[key]) {
            nonEmptyAdvantages.add(key); // Добавляем непустые характеристики в Set
          }
        });
      }

      // Фильтруем характеристики, оставляя только непустые
      state.filterParameters.byCheckbox.advantages = state.filterParameters.byCheckbox.advantages.filter(item =>
        nonEmptyAdvantages.has(item.value),
      );
    },

    //Обновить значения для отображения в изменяемом range-слайдере
    updateInputValues(state, action) {
      //Записываем новые значения в активный слайдер
      state.filterParameters.inputsValues[action.payload.slider] = [
        Number(action.payload.values[0]),
        Number(action.payload.values[1]),
      ];
    },

    //Обновить значения для расчетов в изменяемом range-слайдере
    updateSliderValues(state, action) {
      //Проверка на изменение значений - если значения отличаются от максимальных/минимальных, то
      //пользователь взаимодействовал с ползунками, записываем это в interactedParams и
      //записываем новые значения в slidersValues
      //иначе - пользователь вернул значения в исходное положение, записываем false в interactedParams
      //и записываем значения из initialValues в slidersValues
      const slider = action.payload.slider;
      const newSliderValues = [Number(action.payload.values[0]), Number(action.payload.values[1])];
      const extremeValues = state.filterParameters.slidersExtremeValues[slider];
      const initialValues = state.filterParameters.initialValues[slider];
      const newValuesAreEqual = newSliderValues[0] === newSliderValues[1];

      newSliderValues.forEach((value, index) => {
        const key = index === 0 ? 'min' : 'max';
        const preposition = index === 0 ? 'от ' : 'до ';

        if (value !== extremeValues[index]) {
          state.filterParameters.interactedParams[slider][key] = true;
          state.filterParameters.slidersValues[slider][index] = value;
          state.filterParameters.ableToResetParams[slider][index] =
            (!newValuesAreEqual ? preposition + ' ' : '') +
            Math.floor(newSliderValues[0] > 1000000 ? editPriceInMillions(value) : value);
        } else {
          state.filterParameters.interactedParams[slider][key] = false;
          state.filterParameters.slidersValues[slider][index] = initialValues[index];
          state.filterParameters.ableToResetParams[slider][index] = '';
        }
      });

      if (!action.payload.noFiltering) {
        filterSlice.caseReducers.filterFlats(state);
      }
    },

    resetParam(state, action) {
      const sliders = ['floor', 'area', 'price'];
      const checkboxes = ['rooms', 'sections', 'advantages'];
      const param = action.payload;

      if (sliders.includes(param)) {
        state.filterParameters.interactedParams[param].min = false;
        state.filterParameters.interactedParams[param].max = false;
        filterSlice.caseReducers.setExtremeSlidersValues(state);
      }

      if (checkboxes.includes(param)) {
        state.filterParameters.byCheckbox[param].forEach(item => (item.active = false));
      }

      filterSlice.caseReducers.filterFlats(state);
      state.filterParameters.ableToResetParams[param] = [];
    },
    toggleActiveSearchParams(state, action) {
      const { id, value } = action.payload;

      const handleCheckbox = (state, checkboxType, value) => {
        const checkboxes = state.filterParameters.byCheckbox[checkboxType];
        checkboxes.forEach(el => {
          el.active = false;
        });
        state.filterParameters.ableToResetParams[checkboxType] = [];

        if (value !== 'Любой') {
          booleanSwitch(state.filterParameters.byCheckbox, checkboxType, 'active', value);
          state.filterParameters.ableToResetParams[checkboxType].push(value);
        }
      };

      filterSlice.caseReducers.updateTableSorts(state, { payload: id });

      const actionHandlers = {
        slider: () => {
          const { sliderName, newValues } = handleSlider(state, id, value);
          filterSlice.caseReducers.updateSliderValues(state, {
            payload: { slider: sliderName, values: newValues },
          });
          filterSlice.caseReducers.updateInputValues(state, {
            payload: { slider: sliderName, values: newValues },
          });
        },
        rooms: () => handleCheckbox(state, 'rooms', value),
        sections: () => handleCheckbox(state, 'sections', value),
        quarter: () => handleQuarterOrDeadline(state, 'quarter', id, value),
        deadline: () => handleQuarterOrDeadline(state, 'deadline', id, value),
        rare: () => filterSlice.caseReducers.toggleRare(state, { payload: id }),
        installment: () => filterSlice.caseReducers.toggleInstallment(state, { payload: id }),
        promo: () => filterSlice.caseReducers.toggleRedFlats(state, { payload: id }),
        red: () => booleanSwitch(state.filterParameters.byCheckbox, 'redPrice', 'active', value),
        advantages: () => booleanSwitch(state.filterParameters.byCheckbox, 'advantages', 'active', value),
      };

      const actionType = getActionType(id);

      if (actionHandlers[actionType]) {
        actionHandlers[actionType]();
      }

      filterSlice.caseReducers.filterFlats(state);
    },

    filterFlats(state) {
      const setSelectedParams = state => {
        state.filterParameters.selectedParams = {
          rooms: getSelectedParams(state.filterParameters.byCheckbox, 'rooms').replace('0', 'studio'),
          sections: getSelectedParams(state.filterParameters.byCheckbox, 'sections').split(','),
          advantages: getSelectedParams(state.filterParameters.byCheckbox, 'advantages').split(','),
        };
      };

      const helpers = {
        initializeFilters: () => {
          state.filterParameters.amountOfSelectedParams = 0;
          const slidersValues = setSlidersValues(state);
          setSelectedParams(state);
          countSelectedParams(state);
          disableUnselectedCheckboxes(state);
          return slidersValues;
        },

        applyFilters: (filtered, chosenParams) => {
          const byCheckbox = createCheckboxFilters(state);
          const findCheckbox = createFindCheckboxFunctions(state);

          switch (chosenParams.length) {
            case 0:
              state.flats = filtered;
              filterSlice.caseReducers.setAvailableCheckboxes(state);
              break;
            case 1:
              applyOneParamFilter(state, filtered, chosenParams[0], byCheckbox, findCheckbox);
              break;
            case 2:
              applyTwoParamsFilter(state, filtered, chosenParams, byCheckbox, findCheckbox);
              break;
            case 3:
              applyThreeParamsFilter(state, filtered, byCheckbox, findCheckbox);
              break;
            default:
              // Если количество выбранных параметров не соответствует ожидаемым случаям, оставляем список квартир без изменений
              state.flats = filtered;
          }
        },
      };

      const slidersValues = helpers.initializeFilters();
      const filtered = filterBySliders(state.allFlats, slidersValues);
      const chosenParams = getChosenParams(state);

      helpers.applyFilters(filtered, chosenParams);
      filterSlice.caseReducers.setExtremeSlidersValues(state);
    },

    setAvailableCheckboxes(state) {
      if (!state.filterParameters?.byCheckbox) return;

      const checkboxUpdaters = {
        advantages: (flat, update) => {
          if (flat.attributes) {
            Object.keys(flat.attributes).forEach(key => {
              if (flat.attributes[key]) {
                update('advantages', item => item.value === key);
              }
            });
          }
        },
        sections: (flat, update) => {
          update('sections', item => item.value === 'Любой' || String(flat.bulk?.number) === String(item.value));
        },
        rooms: (flat, update) => {
          update(
            'rooms',
            item =>
              item.value === 'Любой' ||
              (item.value === '0' && flat.rooms === 'studio') ||
              flat.rooms === Number(item.value),
          );
        },
      };

      updateCheckboxes(state, checkboxUpdaters);
    },
    setPreviousFiltersValues(state, action) {
      const { slidersValues, byCheckbox, slidersExtremeValues, inputsValues } = action.payload;
      state.filterParameters.byCheckbox = byCheckbox;
      state.filterParameters.slidersExtremeValues = slidersExtremeValues;
      state.filterParameters.inputsValues = inputsValues;

      Object.keys(slidersValues).forEach(key => {
        filterSlice.caseReducers.updateSliderValues(state, {
          payload: { values: slidersValues[key], slider: key, noFiltering: true },
        });
      });

      filterSlice.caseReducers.filterFlats(state);
    },
    resetFilters(state) {
      const checkboxParams = Object.keys(state.filterParameters.byCheckbox);
      state.selectedСategories = [];
      state.filterParameters.amountOfSelectedParams = 0;

      checkboxParams.forEach(item => {
        state.filterParameters.byCheckbox[item] = resetSortingParameter(state.filterParameters.byCheckbox[item]);
      });

      const interactedParams = Object.keys(state.filterParameters.interactedParams);
      interactedParams.forEach(item => {
        state.filterParameters.interactedParams[item].max = false;
        state.filterParameters.interactedParams[item].min = false;
      });

      Object.keys(state.filterParameters.ableToResetParams).forEach(
        key => (state.filterParameters.ableToResetParams[key] = []),
      );

      state.flats = state.allFlats;
      filterSlice.caseReducers.setExtremeSlidersValues(state);
      filterSlice.caseReducers.setAvailableCheckboxes(state);
      state.sortParameters = {
        value: 'area',
        direction: 'asc_',
      };
    },
    sortFlats(state, action) {
      if (['advantages', 'fav', 'scheme'].includes(action.payload.value)) {
        return; // Исключаем фильтр по характеристикам
      }

      let direction = state.sortParameters.direction;
      if (action.payload.placeCall === state.inTableHead) {
        direction = direction === 'asc_' ? 'desc_' : 'asc_';
        state.sortParameters = { value: action.payload.value, direction };
      }

      const sortingStrategies = {
        rooms: () => {
          const array = state.flats.map(flat => changeStudioValue(flat, 'studio', '0'));
          sortNumber(array, state.sortParameters.value, state.sortParameters.direction);
          state.flats = array.map(flat => changeStudioValue(flat, '0', 'studio'));
        },
        sections_number: () => {
          state.flats.sort((a, b) => {
            return direction === 'asc_' ? a.bulk.number - b.bulk.number : b.bulk.number - a.bulk.number;
          });
        },
        price: () => sortFlatsHelper(state.flats, 'currentPrice', direction),
        real_price: () => sortFlatsHelper(state.flats, 'currentPrice', direction),
        default: () => sortNumber(state.flats, state.sortParameters.value, state.sortParameters.direction),
      };

      (sortingStrategies[action.payload.value] || sortingStrategies.default)();
    },
    sortSquareFlats(state, action) {
      state.filterSelect.cellContent = { ...action.payload, active: true };
      state.filterSelect.choices = state.filterSelect.choices.map(el => {
        if (action.payload.id === el.id) {
          return { ...el, active: true };
        }
        return { ...el, active: false };
      });

      switch (action.payload.title) {
        case 'Цена по возрастанию':
          state.flats = state.flats.sort((a, b) => {
            return a.currentPrice - b.currentPrice;
          });
          break;
        case 'Цена по убыванию':
          state.flats = state.flats.sort((a, b) => b.currentPrice - a.currentPrice);
          break;
        case 'Площадь по возрастанию':
          state.flats = state.flats.sort((a, b) => {
            return a.area - b.area;
          });
          break;

        case 'Площадь по убыванию':
          state.flats = state.flats.sort((a, b) => {
            return b.area - a.area;
          });
          break;
        default:
          break;
      }
    },
    setCurrentFlat(state, action) {
      state.apartmentNumber = action.payload;
      state.currentFlat = state.flats.find(item => item.number === state.apartmentNumber);
    },
    resetCurrentFlat(state) {
      state.currentFlat = '';
      state.apartmentNumber = '';
    },
    setBenefits(state, action) {
      //При переходе на страницу записываем в стейт все доступные формы привелегий,
      //"Выгодная цена" по умолчанию активна
      state.benefits = action.payload.map(item => checkParameterAndToggleStatus(item.id, 125191, item, 116457));
      state.activeBenefit = state.benefits.find(item => item.checked === true);
    },
    toggleActiveBenefit(state, action) {
      if (action.payload !== state.activeBenefit.id) {
        state.benefits = state.benefits.map(item => checkParameterAndToggleStatus(item.id, action.payload, item));
        state.activeBenefit = state.benefits.find(item => item.checked === true);
      }
    },
    toggleRedFlats(state, action) {
      state.toggleRedFlats = action.payload;
    },
    toggleInstallment(state, action) {
      state.installment = action.payload;
    },
    toggleRare(state, action) {
      state.rare = action.payload;
    },
    setSeoData(state, action) {
      state.seoData = action.payload;
    },
  },
  extraReducers: {
    [fetchFlats.pending]: state => {
      state.urls.getResidential.status = 'loading';
      state.urls.getResidential.error = null;
    },
    [fetchFlats.fulfilled]: (state, action) => {
      const { data, withProcessing } = action.payload;
      state.flatsData = data;
      state.flats = data.flats;
      state.allFlats = data.flats;

      if (!withProcessing) return;

      const maxFloor = calcTheLargest(state.flats, 'floor');
      const minFloor = calcTheLeast(state.flats, 'floor', maxFloor);
      const minPrice = data.price.min,
        maxPrice = data.price.max,
        minArea = data.area.min,
        maxArea = data.area.max;

      if (maxFloor && minFloor && minPrice && maxPrice && minArea && maxArea) {
        state.filterParameters.initialValues = {
          area: [minArea, maxArea],
          price: [minPrice, maxPrice],
          floor: [minFloor, maxFloor],
        };
      } else {
        state.filterParameters.initialValues = {
          area: [2, 20],
          price: [2, 20],
          floor: [2, 20],
        };
      }

      state.filterParameters.byCheckbox.sections = [
        { name: 'Любой', value: 'Любой', active: false, disabled: false },
      ].concat(
        action.payload.data.bulks.map(item => {
          return { name: String(item.number), value: String(item.number), active: false, disabled: false };
        }),
      );

      const maxFloorFromAllFlats = calcTheLargest(state.allFlats, 'floor');
      state.maxFloor = maxFloorFromAllFlats;
      // проверка атрибутов
      filterSlice.caseReducers.checkRelevantAttributes(state);
      filterSlice.caseReducers.setExtremeSlidersValues(state);
      filterSlice.caseReducers.setAvailableCheckboxes(state);

      state.redFlats = data?.flats?.filter(item => item.redPrice).length > 0;
      state.urls.getResidential.initial = true;
      state.urls.getResidential.status = true;

      filterSlice.caseReducers.sortFlats(state, {
        payload: {
          value: state.sortParameters.value,
          placeCall: state.inForm,
          array: 'flats',
        },
      });
    },
    [fetchFlats.rejected]: state => {
      console.log('Ошибка при запросе на сервер');
      state.urls.getResidential.error = 'error';
      state.urls.getResidential.status = null;
    },

    [fetchFlat.pending]: () => {},
    [fetchFlat.fulfilled]: (state, action) => {
      state.flat = action.payload;
      state.similarFlats = action.payload.similar;
    },
    [fetchFlat.rejected]: () => {
      console.log('Ошибка загрузки квартиры');
    },

    [fetchAttributes.pending]: () => {},
    [fetchAttributes.fulfilled]: (state, action) => {
      const modifiedAttributes = action?.payload?.map(attr => {
        return {
          id: attr.id,
          value: attr.name,
          name: attr.description,
          category: attr.category,
          active: false,
          disabled: false,
        };
      });
      state.filterParameters.byCheckbox.advantages = modifiedAttributes;
      filterSlice.caseReducers.checkRelevantAttributes(state);
    },
    [fetchAttributes.rejected]: state => {
      state.filterParameters.byCheckbox.advantages = null;
      console.log('Ошибка загрузки атрибутов');
    },
  },
});

export const {
  updateTableSorts,
  toggleActiveSearchParams,
  sortFlats,
  sortSquareFlats,
  resetFilters,
  setToggleMenu,
  updateInputValues,
  updateSliderValues,
  setPreviousFiltersValues,
  resetParam,
  toggleRedFlats,
  toggleInstallment,
  toggleRare,
  setSeoData,
} = filterSlice.actions;

export default filterSlice.reducer;
