import { Helmet } from 'react-helmet-async';

const HelmetComponent = ({ page, title, description, image, width, height }) => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <link rel='canonical' href={`https://moments.forma.ru/${page}`} />
        {title && <title>{title}</title>}
        {description && <meta name='description' content={description} />}
        <meta property='og:type' content='website' />
        {title && <meta property='og:title' content={title} />}
        {title && <meta property='og:site_name' content={title} />}
        {description && <meta property='og:description' content={description} />}
        {width && <meta property='og:image:width' content='1200' />}
        {height && <meta property='og:image:height' content='628' />}
        {title && <meta name='twitter:title' content={title} />}
        {description && <meta name='twitter:description' content={description} />}
        {title && <meta name='vk:title' content={title} />}
        {description && <meta name='vk:description' content={description} />}
        {image && <meta property='og:image' content={image} />}
      </Helmet>
    </>
  );
};

export default HelmetComponent;
