import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  defineDevice,
  deviceSelector,
  orientationSelector,
  useragentSelector,
  widthSelector,
} from '../../entities/UserSlice';

export function useUserService() {
  const dispatch = useDispatch();
  const device = useSelector(deviceSelector);
  const useragent = useSelector(useragentSelector);
  const orientation = useSelector(orientationSelector);
  const width = useSelector(widthSelector);

  useEffect(() => {
    if (!width || !useragent || !device || !orientation) {
      dispatch(
        defineDevice({
          width: window.innerWidth,
          userAgent: navigator.userAgent,
        }),
      );
    }
  }, [width, useragent, orientation, device, dispatch]);

  return {
    device,
    useragent,
    orientation,
    width,
  };
}
