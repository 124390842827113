import { isMobile } from 'react-device-detect';
import { Link, useParams } from 'react-router-dom';

import ROUTES from '../../../../layout/routes';
import style from './NextLink.module.scss';

function NextLink({ activeFloor, activeFlat, bulk, activeQueue, flatId }) {
  const { houseId } = useParams();
  const { floorId } = useParams();
  const { apartmentId } = useParams();
  const { bulkId } = useParams();

  const getButtonText = floorId => {
    if (floorId && !isMobile) return 'Выбрать квартиру';
    if (floorId && isMobile) return 'Выбрать';
    return 'Продолжить';
  };

  const renderNextButton = (to, condition) => {
    if (condition) {
      return (
        <Link to={to}>
          <div className={style.nextButton}>{getButtonText()}</div>
        </Link>
      );
    }
    return null;
  };

  return (
    <>
      {renderNextButton(`${ROUTES.flats.visual.root}/${activeQueue}`, !houseId && activeQueue === 2)}
      {renderNextButton(`${ROUTES.flats.visual.root}/${activeQueue}/9335`, !houseId && activeQueue === 1)}
      {renderNextButton(
        `${ROUTES.flats.visual.root}/${houseId}/${bulk}`,
        !floorId &&
          houseId &&
          !bulkId &&
          (bulk.toString() === '9758' || bulk.toString() === '10099' || bulk.toString() === '10100'),
      )}
      {renderNextButton(`${ROUTES.flats.visual.root}/${houseId}/${bulkId}/${activeFloor}`, !floorId && activeFloor)}
      {renderNextButton(`/flat/${flatId}`, floorId && !apartmentId && activeFlat !== null)}
    </>
  );
}

export default NextLink;
