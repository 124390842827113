import { isMobile } from 'react-device-detect';
import { RedPriceTooltip } from '../../../shared/red-price-tooltip/RedPriceTooltip';
import formatLongPrice from '../../../tools/_format-long-price';
import style from './VisualFlatPrice.module.scss';

function VisualFlatPrice({ flatData, priceRef }) {
  if (!flatData) return;

  return (
    <>
      {flatData[0].redPrice ? (
        <div className={style.priceDiscount} ref={priceRef}>
          <RedPriceTooltip text='Только при 100% оплате или ипотеке без субсидирования' someStyles={style.tooltip}>
            <p className={style.priceDiscount__amount}>
              {formatLongPrice(flatData[0]?.currentPrice)} <span>₽</span>
            </p>
            <div className={style.priceDiscount__discount}>
              <p className={style.priceDiscount__discount__text}>
                {formatLongPrice(flatData[0]?.real_price)} <span>₽</span>
              </p>
              {!isMobile && <p>%</p>}
            </div>
          </RedPriceTooltip>
        </div>
      ) : (
        <p className={style.price} ref={priceRef}>
          {flatData[0].price} ₽
        </p>
      )}
    </>
  );
}

export default VisualFlatPrice;
