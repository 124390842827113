import cn from 'classnames';
import style from './BlurTooltip.module.scss';

const BlurTooltip = ({ children, someStyles, id, ...others }) => {
  return (
    <>
      <div id={id} className={cn(style.blurTooltip, someStyles)} {...others}>
        {children}
      </div>
    </>
  );
};

export default BlurTooltip;
