export const setSlidersValues = state => {
  const { interactedParams, slidersValues, initialValues } = state.filterParameters;
  const result = { price: [0, 0], area: [0, 0], floor: [0, 0] };

  Object.keys(interactedParams).forEach(key => {
    result[key][0] = interactedParams[key].min ? slidersValues[key][0] : initialValues[key][0];
    result[key][1] = interactedParams[key].max ? slidersValues[key][1] : initialValues[key][1];

    if (interactedParams[key].min || interactedParams[key].max) {
      state.filterParameters.amountOfSelectedParams++;
    }
  });

  return result;
};

export const countSelectedParams = state => {
  const { rooms, sections, advantages } = state.filterParameters.selectedParams;
  if (advantages[0] && advantages.length > 0) {
    state.filterParameters.amountOfSelectedParams += advantages.length;
  }
  if (rooms && rooms !== 'Любой') {
    state.filterParameters.amountOfSelectedParams++;
  }
  if (sections[0] && !sections.includes('Любой')) {
    state.filterParameters.amountOfSelectedParams++;
  }
};

export const disableUnselectedCheckboxes = state => {
  Object.values(state.filterParameters.byCheckbox).forEach(checkboxGroup => {
    checkboxGroup.forEach(item => {
      item.disabled = !item.active;
    });
  });
};

export const filterBySliders = (flats, slidersValues) => {
  return flats.filter(
    flat =>
      flat.currentPrice >= slidersValues.price[0] &&
      flat.currentPrice <= slidersValues.price[1] &&
      flat.area >= slidersValues.area[0] &&
      flat.area <= slidersValues.area[1] &&
      flat.floor >= slidersValues.floor[0] &&
      flat.floor <= slidersValues.floor[1],
  );
};

export const getChosenParams = state => {
  const params = ['rooms', 'sections', 'advantages'];
  return params.filter(param => {
    const value = state.filterParameters.selectedParams[param];
    return (value && value.length === 0) || (value.length > 0 && value[0]);
  });
};

export const createCheckboxFilters = state => {
  const { rooms, sections, advantages } = state.filterParameters.selectedParams;
  return {
    rooms: flat => !rooms || rooms === 'Любой' || rooms.includes(flat.rooms),
    sections: flat => !sections.length || sections.includes('Любой') || sections.includes(String(flat.bulk.number)),
    advantages: flat => advantages.every(attr => flat.attributes[attr]),
  };
};

export const createFindCheckboxFunctions = state => {
  return {
    rooms: flat => {
      state.filterParameters.byCheckbox.rooms.forEach(item => {
        if (
          item.value === 'Любой' ||
          (item.value === '0' && flat.rooms === 'studio') ||
          flat.rooms === Number(item.value)
        ) {
          item.disabled = false;
        }
      });
    },
    sections: flat => {
      state.filterParameters.byCheckbox.sections.forEach(item => {
        if (item.value === 'Любой' || String(flat.bulk.number) === String(item.value)) {
          item.disabled = false;
        }
      });
    },
    advantages: flat => {
      state.filterParameters.byCheckbox.advantages.forEach(item => {
        if (flat.attributes[item.value]) {
          item.disabled = false;
        }
      });
    },
  };
};

export const applyOneParamFilter = (state, filtered, chosenParam, byCheckbox, findCheckbox) => {
  if (chosenParam !== 'advantages') {
    filtered.forEach(flat => findCheckbox[chosenParam](flat));
  }

  state.flats = filtered.filter(byCheckbox[chosenParam]);
  state.flats.forEach(flat => findCheckbox.advantages(flat));

  ['rooms', 'sections', 'advantages']
    .filter(param => param !== chosenParam && param !== 'advantages')
    .forEach(param => state.flats.forEach(flat => findCheckbox[param](flat)));
};

export const applyTwoParamsFilter = (state, filtered, chosenParams, byCheckbox, findCheckbox) => {
  const thirdParam = ['rooms', 'sections', 'advantages'].find(param => !chosenParams.includes(param));

  state.flats = filtered.filter(flat => byCheckbox[chosenParams[0]](flat) && byCheckbox[chosenParams[1]](flat));

  state.flats.forEach(flat => {
    findCheckbox.advantages(flat);
    findCheckbox[thirdParam](flat);
  });

  if (chosenParams[1] !== 'advantages') {
    filtered.filter(byCheckbox[chosenParams[0]]).forEach(flat => findCheckbox[chosenParams[1]](flat));
  }

  if (chosenParams[0] !== 'advantages') {
    filtered.filter(byCheckbox[chosenParams[1]]).forEach(flat => findCheckbox[chosenParams[0]](flat));
  }
};

export const applyThreeParamsFilter = (state, filtered, byCheckbox, findCheckbox) => {
  state.flats = filtered.filter(
    flat => byCheckbox.sections(flat) && byCheckbox.rooms(flat) && byCheckbox.advantages(flat),
  );

  state.flats.forEach(flat => findCheckbox.advantages(flat));

  filtered
    .filter(byCheckbox.sections)
    .filter(byCheckbox.advantages)
    .forEach(flat => findCheckbox.rooms(flat));
  filtered
    .filter(byCheckbox.rooms)
    .filter(byCheckbox.advantages)
    .forEach(flat => findCheckbox.sections(flat));
};

export const handleSlider = (state, id, value) => {
  const sliderType = id.includes('_min') ? 'min' : 'max';
  const sliderName = id.slice(0, -4);
  const newValues = [...state.filterParameters.slidersValues[sliderName]];
  newValues[sliderType === 'min' ? 0 : 1] = Number(value);
  return { sliderName, newValues };
};

export const handleQuarterOrDeadline = (state, type, id, value) => {
  state.filterParameters.byCheckbox[type] = state.filterParameters.byCheckbox[type].map(el => ({
    ...el,
    active: value === el.value,
  }));

  if (value === 'Любой') {
    state.countFilteres = state.countFilteres.filter(el => el !== id);
  } else if (!state.countFilteres.includes(id)) {
    state.countFilteres.push(id);
  }
};

export const getActionType = id => {
  if (id.includes('_min') || id.includes('_max')) return 'slider';
  if (id.includes('rooms')) return 'rooms';
  if (id.includes('sections')) return 'sections';
  if (id.includes('quarter')) return 'quarter';
  if (id.includes('deadline')) return 'deadline';
  return id;
};

export const updateCheckboxes = (state, updaters) => {
  // Сначала отключаем все чекбоксы
  Object.values(state.filterParameters.byCheckbox).forEach(group => {
    if (Array.isArray(group)) {
      group.forEach(item => {
        item.disabled = !item.active;
      });
    }
  });

  // Функция для включения чекбоксов
  const enableCheckbox = (group, condition) => {
    if (state.filterParameters.byCheckbox[group]) {
      state.filterParameters.byCheckbox[group].forEach(item => {
        if (condition(item)) {
          item.disabled = false;
        }
      });
    }
  };

  // Применяем обновления для каждой квартиры
  state.flats.forEach(flat => {
    Object.values(updaters).forEach(updater => updater(flat, enableCheckbox));
  });
};

export const changeStudioValue = (flat, from, to) => {
  return flat.rooms === from ? { ...flat, rooms: to } : flat;
};

export const sortFlatsHelper = (flats, property, direction) => {
  flats.sort((a, b) => {
    return direction === 'asc_' ? a[property] - b[property] : b[property] - a[property];
  });
};
