import cn from 'classnames';
import { useEffect, useState } from 'react';
import icon from '../../assets/images/architecture_images/icons/plus.svg';
import style from './ImageWrapperMob.module.scss';

function ImageWrapperMob({ children, className }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.classList.add(style.scrollDisabled);
    }
    if (open === false) {
      document.body.classList.remove(style.scrollDisabled);
    }
  }, [open, setOpen]);

  return (
    <>
      {children}

      <button onClick={() => setOpen(true)} className={style.imgButton}>
        <img className={cn(style.icon, className)} src={icon} alt='plus' />
      </button>

      {open && (
        <div className={style.modal} onClick={() => setOpen(false)} role='button' tabIndex='0'>
          {children}
        </div>
      )}
    </>
  );
}

export default ImageWrapperMob;
