import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, {
  retries: 2, // Количество попыток
  retryDelay: retryCount => retryCount * 2000, // Задержка между попытками
  retryCondition: error => {
    return error.code === 'ECONNABORTED' || error.response?.status >= 500;
  },
});

export const fetchData = async (url, thunkApi) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
};

function onApiError(thunkApi, error) {
  console.log(error);
  let message;
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.data.message) {
        message = error.response.data.message;
      } else if (error.response.data.error) {
        message = error.response.data.error;
      } else {
        message = JSON.stringify(error.response.data);
      }
    } else if (error.message) {
      message = error.message;
    } else {
      message = JSON.stringify(error);
    }
  } else {
    message = JSON.stringify(error);
  }
  return thunkApi.rejectWithValue(message);
}
