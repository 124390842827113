import { useMemo } from 'react';

import { useUserService } from '../../tools/hooks/useUserService';
import style from './LoaderCircle.module.scss';

const LoaderCircle = ({ isLoading, someStyles }) => {
  const { device } = useUserService();

  const isAppleDevice = useMemo(() => {
    return /(iPad|iPhone|Mac)/i.test(navigator.userAgent);
  }, []);

  if (!device) return;

  return (
    <div className={style.nl_reploader_father + ' ' + `${isLoading ? style.__hide : ''}` + ' ' + someStyles}>
      <div className={style.nl_preloader}>
        <span className={isAppleDevice ? style.loaderApple : style.loader} />
      </div>
    </div>
  );
};

export default LoaderCircle;
