import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { openParkingFiltersSelector, openParkingSortSelector } from '../../entities/ComponentsSlice';
import ROUTES from '../../layout/routes';
import Portal from '../../shared/portal/Portal';
import { handleBodyLock } from '../../tools/_handle-body-lock';
import { handleBodyUnlock } from '../../tools/_handle-body-unlock';
import ScrollHelperParking from '../../tools/helpers/ScrollHelperParking';
import { ParkingCharacteristics } from '../../widgets/parking/ParkingCharacteristics/ParkingCharacteristics';
import { ParkingFilters } from '../../widgets/parking/ParkingFilters/ParkingFilters';
import { ParkingFiltersMob } from '../../widgets/parking/ParkingFiltersMob/ParkingFiltersMob';
import { ParkingHeader } from '../../widgets/parking/ParkingHeader/ParkingHeader';
import { ParkingPlaces } from '../../widgets/parking/ParkingPlaces/ParkingPlaces';
import { ParkingPlacesMob } from '../../widgets/parking/ParkingPlaces/ParkingPlacesMob';
import { ParkingSortMob } from '../../widgets/parking/ParkingSortMob/ParkingSortMob';

const breadCrumbsApi = {
  color: 'white',
  crumbs: [
    {
      id: 1,
      text: 'Главная',
      link: ROUTES.root,
    },
    {
      id: 2,
      text: 'Выбрать квартиру по параметрам',
    },
  ],
};

export const ParkingPage = () => {
  const isOpen = useSelector(openParkingFiltersSelector);
  const sortIsOpen = useSelector(openParkingSortSelector);

  useEffect(() => {
    if (isOpen || sortIsOpen) {
      document.body.style.overflow = 'hidden';
      handleBodyLock();
    } else {
      document.body.style.overflow = 'auto';
      handleBodyUnlock();
    }
  }, [isOpen, sortIsOpen]);

  return (
    <>
      <ParkingHeader breadCrumbsApi={breadCrumbsApi} />
      {isMobileOnly && isOpen && (
        <Portal>
          <ParkingFiltersMob />
        </Portal>
      )}
      {sortIsOpen && <ParkingSortMob />}
      <ParkingFilters />
      {isMobileOnly && <ParkingPlacesMob />}
      {!isMobileOnly && <ParkingPlaces />}
      <ParkingCharacteristics />
      <ScrollHelperParking />
    </>
  );
};
