import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchNews = createAsyncThunk('newsSlice/fetchNews', async (_, thunkApi) => {
  return fetchData(api.urlNews, thunkApi);
});

export const fetchSlider = createAsyncThunk('newsSlice/fetchSlider', async (sliderName, thunkApi) => {
  const url = `/api/slider/${sliderName}`;
  return fetchData(url, thunkApi);
});

const initialState = {
  news: null,
  slider: null,
  fetchError: false,
};

export const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNews.fulfilled]: (state, action) => {
      state.news = action.payload;
    },
    [fetchNews.rejected]: (state, action) => {
      state.fetchError = true;
    },
    [fetchSlider.fulfilled]: (state, action) => {
      state.slider = action.payload;
    },
    [fetchSlider.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const newsSelector = state => state.newsInfo.news;
export const sliderSelector = state => state.newsInfo.slider;
export const fetchErrorSelector = state => state.newsInfo.fetchError;

export default newsSlice.reducer;
