import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchNavList = createAsyncThunk('termsSlice/fetchNavList', async (_, thunkApi) => {
  return fetchData(api.urlNavList, thunkApi);
});

export const fetchTradeIn = createAsyncThunk('termsSlice/fetchTradeIn', async (_, thunkApi) => {
  return fetchData(api.urlTradeIn, thunkApi);
});

export const fetchQa = createAsyncThunk('termsSlice/fetchQa', async (_, thunkApi) => {
  return fetchData(api.urlQa, thunkApi);
});

export const fetchSpecialMortgages = createAsyncThunk('termsSlice/fetchSpecialMortgages', async (_, thunkApi) => {
  return fetchData(api.urlSpecialMortgages, thunkApi);
});

export const fetchMortgages = createAsyncThunk('termsSlice/fetchMortgages', async (_, thunkApi) => {
  return fetchData(api.urlMortgages, thunkApi);
});

export const fetchCalcData = createAsyncThunk('termsSlice/fetchCalcData', async (_, thunkApi) => {
  return fetchData(api.urlCalcData, thunkApi);
});

export const fetchInstallments = createAsyncThunk('termsSlice/fetchInstallments', async (_, thunkApi) => {
  return fetchData(api.urlInstallments, thunkApi);
});

export const fetchSpecialInstallments = createAsyncThunk('termsSlice/fetchSpecialInstallments', async (_, thunkApi) => {
  return fetchData(api.urlSpecialInstallments, thunkApi);
});

export const fetchCash = createAsyncThunk('termsSlice/fetchCash', async (_, thunkApi) => {
  return fetchData(api.urlCash, thunkApi);
});

export const fetchBuyout = createAsyncThunk('termsSlice/fetchBuyout', async (_, thunkApi) => {
  return fetchData(api.urlBuyout, thunkApi);
});

export const fetchCashback = createAsyncThunk('termsSlice/fetchCashback', async (_, thunkApi) => {
  return fetchData(api.urlCashback, thunkApi);
});

export const fetchPromoCards = createAsyncThunk('termsSlice/fetchPromoCards', async (_, thunkApi) => {
  return fetchData(api.urlPromoCards, thunkApi);
});

const initialState = {
  navList: null,
  tradeIn: null,
  qa: null,
  specialMortgages: null,
  mortgages: null,
  calcData: null,
  installments: null,
  specialInstallments: null,
  cash: null,
  flats: null,
  fetchError: false,
  cashback: null,
  buyout: null,
  promoCards: null,
  activeTab: 0,
};

export const termsSlice = createSlice({
  name: 'termsSlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: {
    [fetchNavList.fulfilled]: (state, action) => {
      state.navList = action.payload;
    },
    [fetchNavList.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchTradeIn.fulfilled]: (state, action) => {
      state.tradeIn = action.payload;
    },
    [fetchTradeIn.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchQa.fulfilled]: (state, action) => {
      state.qa = action.payload;
    },
    [fetchQa.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchSpecialMortgages.fulfilled]: (state, action) => {
      state.specialMortgages = action.payload;
    },
    [fetchSpecialMortgages.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchMortgages.fulfilled]: (state, action) => {
      state.mortgages = action.payload;
    },
    [fetchMortgages.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCalcData.fulfilled]: (state, action) => {
      state.calcData = action.payload;
    },
    [fetchCalcData.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchInstallments.fulfilled]: (state, action) => {
      state.installments = action.payload;
    },
    [fetchInstallments.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchSpecialInstallments.fulfilled]: (state, action) => {
      state.specialInstallments = action.payload;
    },
    [fetchSpecialInstallments.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCash.fulfilled]: (state, action) => {
      state.cash = action.payload;
    },
    [fetchCash.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchBuyout.fulfilled]: (state, action) => {
      state.buyout = action.payload;
    },
    [fetchBuyout.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCashback.fulfilled]: (state, action) => {
      state.cashback = action.payload;
    },
    [fetchCashback.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchPromoCards.fulfilled]: (state, action) => {
      state.promoCards = action.payload;
    },
    [fetchPromoCards.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { setActiveTab } = termsSlice.actions;

export const navListSelector = state => state.termsInfo.navList;
export const calcDataSelector = state => state.termsInfo.calcData;
export const specialInstallmentsSelector = state => state.termsInfo.specialInstallments;
export const installmentsSelector = state => state.termsInfo.installments;
export const cashSelector = state => state.termsInfo.cash;
export const cashbackSelector = state => state.termsInfo.cashback;
export const buyoutSelector = state => state.termsInfo.buyout;
export const promoCardsSelector = state => state.termsInfo.promoCards;
export const mortgagesSelector = state => state.termsInfo.mortgages;
export const specialMortgagesSelector = state => state.termsInfo.specialMortgages;
export const qaSelector = state => state.termsInfo.qa;
export const tradeInSelector = state => state.termsInfo.tradeIn;
export const fetchErrorSelector = state => state.termsInfo.fetchError;
export const activeTabSelector = state => state.termsInfo.activeTab;

export default termsSlice.reducer;
