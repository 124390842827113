import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchVisualFlat = createAsyncThunk('visualFlatSlice/fetchVisualFlat', async (number, thunkApi) => {
  return fetchData(api.urlFlats, thunkApi);
});

export const fetchVisualAttributes = createAsyncThunk('flatsSlice/fetchAttributes', async (_, thunkApi) => {
  const response = await fetchData(api.urlAttributes, thunkApi);
  return response.data;
});

const initialState = {
  visualFlat: null,
  activeFloor: null,
  activeFlat: null,
  roomsInFlat: null,
  fetchError: false,
  floor: null,
  flatData: null,
  bottomSheetIsOpen: false,
  attributes: null,
};

export const visualFlatSlice = createSlice({
  name: 'visualFlatSlice',
  initialState,
  reducers: {
    setVisualFlat: (state, action) => {
      state.visualFlat = action.payload;
    },
    setActiveFloor: (state, action) => {
      state.activeFloor = action.payload;
    },
    setActiveFlat: (state, action) => {
      state.activeFlat = action.payload;
    },
    setRoomsInFlat: (state, action) => {
      state.roomsInFlat = action.payload;
    },
    getFloor: (state, action) => {
      state.floor = action.payload;
    },
    setFlatData: (state, action) => {
      state.flatData = action.payload;
    },
    setBottomSheetIsOpen: (state, action) => {
      state.bottomSheetIsOpen = action.payload;
    },
  },
  extraReducers: {
    [fetchVisualFlat.pending]: (state, action) => {},
    [fetchVisualFlat.fulfilled]: (state, action) => {
      state.visualFlat = action.payload;
    },
    [fetchVisualFlat.rejected]: (state, action) => {
      state.fetchError = true;
    },
    [fetchVisualAttributes.pending]: () => {},
    [fetchVisualAttributes.fulfilled]: (state, action) => {
      const modifiedAttributes = action.payload.map(attr => {
        return {
          id: attr.id,
          value: attr.name,
          name: attr.description,
          category: attr.category,
          active: false,
          disabled: false,
        };
      });
      state.attributes = modifiedAttributes;
    },
    [fetchVisualAttributes.rejected]: () => {
      console.log('Ошибка загрузки атрибутов');
    },
  },
});

export const {
  setVisualFlat,
  setActiveFloor,
  setActiveFlat,
  setRoomsInFlat,
  getFloor,
  setFlatData,
  setBottomSheetIsOpen,
} = visualFlatSlice.actions;

export const visualFlatSelector = state => state.visualFlat.visualFlat;
export const fetchErrorSelector = state => state.visualFlat.fetchError;
export const activeFloorSelector = state => state.visualFlat.activeFloor;
export const fetchActiveFlat = state => state.visualFlat.activeFlat;
export const fetchFlatData = state => state.visualFlat.flatData;
export const sheetIsOpenSelector = state => state.visualFloor.bottomSheetIsOpen;
export const visaulAttributesSelector = state => state.visualFlat.attributes;

export default visualFlatSlice.reducer;
