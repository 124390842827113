import { Suspense } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../layout/routes';
import BreadCrumbs from '../../shared/bread-crumbs/BreadCrumbs';
import HelmetComponent from '../../shared/helmet-component/HelmetComponent';
import ScrollHelperStandart from '../../tools/helpers/ScrollHelperStandart';
import WhiteBox from '../../widgets/white-box/WhiteBox';
import WhiteBoxMobile from '../../widgets/white-box/WhiteBoxMobile';
import style from './WhiteBoxPage.module.scss';

const breadCrumbsApi = {
  color: '#1c1c1c',
  crumbs: [
    {
      id: 1,
      text: 'Главная',
      link: ROUTES.root,
    },
    {
      id: 2,
      text: 'Отделка White box',
    },
  ],
};

function WhiteBoxPage() {
  const { pathname } = useLocation();
  return (
    <>
      <HelmetComponent
        page={pathname?.slice(1)}
        title='Отделка White box | ЖК Moments'
        description='Отделка White box. Официальный сайт ЖК бизнес-класса Moments от FORMA. Проекты, дома, квартиры, условия покупки и многое другое.'
      />
      <div className={style.content}>
        <h1 style={{ display: 'none' }}>Отделка White box</h1>

        <div className={style.bredCrumbs_block}>
          <BreadCrumbs item={breadCrumbsApi} />
        </div>

        {!isMobileOnly && (
          <Suspense>
            <WhiteBox show={true} />
          </Suspense>
        )}
        {isMobileOnly && (
          <Suspense>
            <WhiteBoxMobile show={true} />
          </Suspense>
        )}
      </div>
      <ScrollHelperStandart />
    </>
  );
}

export default WhiteBoxPage;
