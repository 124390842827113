import { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { handleHeaderColorScheme } from '../../entities/ComponentsSlice';
import { deviceSelector } from '../../entities/UserSlice';
import useScroll from '../hooks/useScroll';

const ScrollHelperBlackVisual = () => {
  const dispatch = useDispatch();
  const { floorId, houseId, bulkId } = useParams();
  const { scroll } = useLocomotiveScroll();
  const { scrollY } = useScroll();
  const device = useSelector(deviceSelector);
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (device === 'isMobile' && houseId && bulkId && !floorId)
        dispatch(handleHeaderColorScheme('whiteOnTransparent'));
      else dispatch(handleHeaderColorScheme('blackOnTransparentThree'));
    }, 300);
  }, [pathname]);

  useEffect(() => {
    if (scroll) {
      scroll.on('scroll', () => {
        if (scroll.scroll.instance.scroll.y < 3000) dispatch(handleHeaderColorScheme('blackOnTransparentThree'));
        else dispatch(handleHeaderColorScheme(''));
      });
    }
  }, [scroll, scrollY]);

  return null;
};

export default ScrollHelperBlackVisual;
