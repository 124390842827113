import Svg from 'react-inlinesvg';
import { useNavigate, useParams } from 'react-router-dom';

import arrowIcon from '../../../../assets/images/visual_images/backArrow.svg';
import ROUTES from '../../../../layout/routes';
import style from './BackLink.module.scss';

function BackLink() {
  const navigate = useNavigate();
  const { houseId, bulkId, floorId } = useParams();

  const rootLink = `${ROUTES.flats.visual.root}`;
  const bulkLink = `${ROUTES.flats.visual.root}/${houseId}`;
  const floorLink = `${ROUTES.flats.visual.root}/${houseId}/${bulkId}`;

  const switchQueue = () => {
    if (houseId === '1') return !floorId ? navigate(rootLink) : navigate(floorLink);

    if (houseId === '2') {
      if (!bulkId) return navigate(rootLink);
      if (bulkId && !floorId) return navigate(bulkLink);
      return navigate(floorLink);
    }
  };

  return (
    <>
      {houseId ? (
        <div
          className={floorId ? style.backLinkGrey : style.backLink}
          onClick={() => switchQueue()}
          role='button'
          tabIndex='0'
        >
          <Svg src={arrowIcon} className={style.backIcon} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default BackLink;
