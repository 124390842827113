import { configureStore } from '@reduxjs/toolkit';
import componentssliceReducer from './ComponentsSlice.js';
import documentsReducer from './DocumentsSlice';
import equalizerReducer from './EqualizerSlice';
import favoriteFlatsReducer from './FavoriteFlatSlice.js';
import filterReducer from './FilterSlice.js';
import flatReducer from './FlatSlice.js';
import galleryReducer from './GallerySlice';
import newsReducer from './NewsSlice.js';
import progressReducer from './ProgressSlice';
import projectsliceReducer from './ProjectSlice.js';
import termsReducer from './TermsSlice.js';
import usersliceReducer from './UserSlice';
import visualBulkReducer from './VisualBulkSlice.js';
import visualFlatReducer from './VisualFlatSlice.js';
import visualFloorReducer from './VisualFloorSlice.js';
import visualReducer from './VisualSlice';
import parkingReducer from './ParkingSlice.js';

export const store = configureStore({
  reducer: {
    componentsstate: componentssliceReducer,
    userinfo: usersliceReducer,
    projectinfo: projectsliceReducer,
    filters: filterReducer,
    favoriteFlats: favoriteFlatsReducer,
    flatInfo: flatReducer,
    termsInfo: termsReducer,
    documentsInfo: documentsReducer,
    newsInfo: newsReducer,
    progressInfo: progressReducer,
    galleryInfo: galleryReducer,
    visual: visualReducer,
    visualFloor: visualFloorReducer,
    visualFlat: visualFlatReducer,
    equalizer: equalizerReducer,
    visualBulk: visualBulkReducer,
    parking: parkingReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});
