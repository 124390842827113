import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { activeQueueSelector } from '../../entities/VisualSlice';
import ROUTES from '../../layout/routes';
import Tab from '../tab-button/Tab';

const VisualNav = ({ mobTabStyle, mobTabActiveStyle, page, mobTabBehindStyle }) => {
  const activeQueue = useSelector(activeQueueSelector);
  const { houseId, bulkId, floorId } = useParams();
  const rootLink = `${ROUTES.flats.visual.root}`;
  const bulkLink = `${ROUTES.flats.visual.root}/${houseId}`;
  const floorLink = `${ROUTES.flats.visual.root}/${houseId}/${bulkId}`;
  const flatLink = `${ROUTES.flats.visual.root}/${houseId}/${bulkId}/${floorId}`;

  const [tabs, setTabs] = useState([
    { id: 0, text: 'Очередь', isActive: false, link: rootLink, disabled: false, hide: false, isBehind: false },
    { id: 1, text: 'Корпус', isActive: false, link: bulkLink, disabled: false, hide: false, isBehind: false },
    { id: 2, text: 'Этаж', isActive: false, link: floorLink, disabled: false, hide: false, isBehind: false },
    { id: 3, text: 'Квартира', isActive: false, link: flatLink, disabled: false, hide: false, isBehind: false },
  ]);

  useEffect(() => {
    const updatedTabs = tabs.map(tab => {
      if ((Number(houseId) === 1 || !houseId) && (activeQueue === 1 || activeQueue === null) && tab.id === 1)
        return { ...tab, hide: true };
      if (tab.id === page - 1) return { ...tab, isActive: true, hide: false };
      return { ...tab, isActive: false, hide: false };
    });
    const activeTabId = updatedTabs.find(tab => tab.isActive).id;
    const finalTabs = updatedTabs.map(tab => {
      return { ...tab, disabled: tab.id > activeTabId, isBehind: tab.id < activeTabId };
    });
    if (JSON.stringify(finalTabs) !== JSON.stringify(tabs)) setTabs(finalTabs);
  }, [activeQueue, page]);

  return (
    <>
      {tabs.map(item => {
        return (
          <Tab
            key={item.id}
            {...item}
            isActive={item.isActive}
            mobTabActiveStyle={mobTabActiveStyle}
            mobTabStyle={mobTabStyle}
            page={page}
            mobTabBehindStyle={mobTabBehindStyle}
          />
        );
      })}
    </>
  );
};

export default VisualNav;
