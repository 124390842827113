import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tagMob from '../../../assets/images/visual_images/tagMob.svg';
import { setBottomSheetIsOpen, sheetIsOpenSelector } from '../../../entities/VisualFloorSlice';
import BlurTooltip from '../../../shared/blur-tooltip/BlurTooltip';
import BackLink from '../VisualBottomLinks/BackLink/BackLink';
import style from './BottomSheet.module.scss';

const getSheetOnFloorStyle = (isOpen, activeForm) => {
  if (!isOpen) return style.sheetForFloor;
  if (activeForm) return style.openSheet;
  return style.openSheetForm;
};

const useSheetStyles = (isOpen, props, floorId) => {
  return useMemo(() => {
    if (floorId) return isOpen ? style.flatSheetOpen : style.sheetForFlat;
    if (props.houseId) return getSheetOnFloorStyle(isOpen, props.activeForm);
    return isOpen ? style.openSheet : style.sheetForRoot;
  }, [isOpen, props.houseId, props.activeForm, floorId]);
};

const BottomSheet = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [startY, setStartY] = useState(null);
  const { floorId, houseId, bulkId } = useParams();
  const dispatch = useDispatch();
  const sheetIsOpen = useSelector(sheetIsOpenSelector);

  const handleTouchStart = useCallback(e => {
    setStartY(e.touches[0].clientY);
  }, []);

  const handleTouchMove = useCallback(
    e => {
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - startY;

      const newIsOpen = deltaY < 0;
      setIsOpen(newIsOpen);
      dispatch(setBottomSheetIsOpen(newIsOpen));
    },
    [dispatch, startY],
  );

  const sheetStyle = useSheetStyles(isOpen, props, floorId);

  useEffect(() => {
    if (sheetIsOpen) setIsOpen(true);
  }, [sheetIsOpen]);

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      className={cn(floorId ? style.flatWrapper : style.wrapper, sheetStyle)}
    >
      <BackLink />

      {isMobileOnly && (!houseId || (houseId && !bulkId)) && (
        <BlurTooltip someStyles={style.tagMob}>
          <SVG src={tagMob} />
        </BlurTooltip>
      )}

      <div className={style.children}>
        <div className={style.toggler} />
        {props.children}
      </div>
    </div>
  );
};

export default BottomSheet;
