import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import Svg from 'react-inlinesvg';
import compass from '../../assets/images/visual_images/rose.svg';
import style from './Compass.module.scss';
import cn from 'classnames';

const Compass = ({ someStyles, bulk, genPlanMob, genPlan }) => {
  const roseRef = useRef(null);
  const cdnLink = `https://cdn.forma.ru/moments/plans/${bulk}/genplan/mini-genplan-moments-site.svg`;
  const [isValid, setIsValid] = useState(false);
  const [link, setLink] = useState(null);
  const [check, setCheck] = useState(false);

  const checkLinkValidity = url => {
    return axios
      .head(url)
      .then(response => {
        if (response.status === 200) {
          return true;
        }
        if (response.status !== 200) {
          return false;
        }
      })
      .catch(error => {
        console.error('Ошибка при проверке ссылки:', error);
        return false;
      });
  };

  useEffect(() => {
    const checkCdnValidity = async () => {
      const isValid = await checkLinkValidity(cdnLink);
      setIsValid(isValid);
      setCheck(true);
    };

    checkCdnValidity();
  }, [cdnLink]);

  const beforeSvgInjection = useCallback(() => {
    const rose = document.querySelector('#compass');
    if (!rose) return;
    const k1 = document.querySelector('#k_1');
    const k21 = document.querySelector('#k2_1');
    if (Number(bulk) === 9335) k1.classList.add(style.active);
    if (Number(bulk) === 9758) k21.classList.add(style.active);
  }, [roseRef, bulk]);

  useEffect(() => {
    if (roseRef && bulk && isValid) beforeSvgInjection();
  }, [roseRef, bulk, isValid, beforeSvgInjection]);

  useEffect(() => {
    if (isValid) setLink(cdnLink);
    if (check && !isValid) setLink(compass);
  }, [isValid, check]);

  return (
    <>
      <div className={cn(someStyles, genPlan && genPlanMob ? style.compassGenplanMob : '')} ref={roseRef}>
        {!isValid && (
          <Svg
            className={style.compass}
            id='compass'
            src={link}
            alt='Роза_ветров'
            onLoad={() => beforeSvgInjection()}
          />
        )}
        {isValid && <Svg className={style.compass} id='compass' src={link} alt='Роза_ветров' />}
      </div>
    </>
  );
};

export default Compass;
