import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Svg from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import image from '../../../assets/images/visual_images/queue.jpg';
import genplan from '../../../assets/images/visual_images/queue.svg';
import imageOld from '../../../assets/images/visual_images/queue_old.jpg';
import genplanOld from '../../../assets/images/visual_images/queue_old.svg';
import {
  handleChangeFormName,
  handleChangeHiddenMessageFormCallBack,
  handleOpenModelForm,
} from '../../../entities/ComponentsSlice';
import { activeBulkSelector, setActiveBulk, setBulkLock } from '../../../entities/VisualBulkSlice';
import style from './BulksPlan.module.scss';

const BulksPlan = ({ sheetRef, someStyles, data, activeBulkId, switchTitle }) => {
  const dispatch = useDispatch();
  const activeBulk = useSelector(activeBulkSelector);
  const leftBlockRef = useRef(null);
  const scrollSnapRef = useRef(null);
  const imageRef = useRef(null);
  const svgRef = useRef(null);
  const [isSvg, setIsSvg] = useState(false);

  const getSvgAndImage = () => {
    if (data.bulks.find(item => item.id === 10100)) {
      return {
        svg: genplan,
        img: image,
      };
    }
    return {
      svg: genplanOld,
      img: imageOld,
    };
  };

  const handleOpenModal = () => {
    dispatch(handleOpenModelForm(true));
  };

  const beforeSvgInjection = useCallback(() => {
    const firstBulk = document.querySelector('#k2_1_9758');
    if (!firstBulk) return;
    const secondBulk = document.querySelector('#k2_2') || document.querySelector('#k2_2_10100');
    if (!secondBulk) return;
    const thirdBulk = document.querySelector('#k2_3_10099');
    if (!thirdBulk) return;

    const bulksArr = [firstBulk, secondBulk, thirdBulk];

    bulksArr.forEach(item => {
      if (activeBulk === item.id) {
        item.classList.add(style.active);
        item.classList.remove(style.hover);
      } else {
        item.classList.remove(style.active);
        item.classList.add(style.hover);
      }
      item.addEventListener('click', () => {
        if (data?.bulks?.some(bulk => bulk.id === Number(item.id?.split('_').slice(2)?.join('_')))) {
          dispatch(setActiveBulk(item.id));
          item.classList.add(style.active);
        } else {
          dispatch(setActiveBulk(item.id));
          handleOpenModal();
          dispatch(handleChangeFormName('houseForm'));
          dispatch(handleChangeHiddenMessageFormCallBack(switchTitle(item.id)));
          dispatch(setBulkLock(true));
        }
      });
    });

    isMobileOnly && setIsSvg(true);
  }, [svgRef, imageRef, activeBulk]);

  useEffect(() => {
    beforeSvgInjection();
  }, [activeBulk]);

  useEffect(() => {
    if (isMobileOnly && svgRef) {
      leftBlockRef.current.style.height = Math.round(sheetRef?.current?.getBoundingClientRect().y) + 32 + 'px';

      if (!svgRef) return;

      const container = scrollSnapRef.current;
      const containerWidth = container.offsetWidth;
      const halfContainerWidth = containerWidth / 2;
      container.scrollLeft = container.scrollWidth / 2 - halfContainerWidth;
    }
  }, [svgRef, isSvg]);

  return (
    <>
      <div className={someStyles} ref={leftBlockRef}>
        <div ref={scrollSnapRef} className={isMobileOnly ? style.bulkBoxMob : style.bulkBox}>
          <img
            id='gen'
            ref={imageRef}
            className={style.image}
            src={getSvgAndImage().img}
            alt=''
            onLoad={() => beforeSvgInjection()}
          />

          <div className={style.svgBox} ref={svgRef}>
            <Svg src={getSvgAndImage().svg} className={style.svg} onLoad={() => beforeSvgInjection()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BulksPlan;
