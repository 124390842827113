import { createSlice } from '@reduxjs/toolkit';

const equalizer = createSlice({
  name: 'equalizer',
  initialState: {
    isEqualizerOpen: false,
    isInstallmentModalOpen: false,
    selectedBenefit: null,
    currentData: {
      currentFirstPayment: null,
      currentLoanTerm: null,
      currentMinMonthFee: null,
      currentBenefitPrice: null,
      currentMortgageBenefit: null,
    },
  },
  reducers: {
    setEqualizerOpened(state, action) {
      state.isEqualizerOpen = action.payload;
    },
    setInstallmentModalOpened(state, action) {
      state.isInstallmentModalOpen = action.payload;
    },
    setSelectedBenefit(state, action) {
      state.selectedBenefit = action.payload;
    },
    setCurrentData(state, action) {
      const { currentFirstPayment, currentLoanTerm, currentMinMonthFee, currentBenefitPrice, currentMortgageBenefit } =
        action.payload;
      state.currentData.currentFirstPayment = currentFirstPayment;
      state.currentData.currentLoanTerm = currentLoanTerm;
      state.currentData.currentMinMonthFee = currentMinMonthFee;
      state.currentData.currentBenefitPrice = currentBenefitPrice;
      state.currentData.currentMortgageBenefit = currentMortgageBenefit;
    },
    resetSelectedBenefit(state) {
      state.selectedBenefit = null;
      state.currentData = {
        currentFirstPayment: null,
        currentLoanTerm: null,
        currentMinMonthFee: null,
        currentBenefitPrice: null,
        currentMortgageBenefit: null,
      };
    },
  },
});

export const {
  setSelectedBenefit,
  setActiveSlide,
  setCurrentData,
  resetSelectedBenefit,
  setEqualizerOpened,
  setInstallmentModalOpened,
} = equalizer.actions;

export default equalizer.reducer;
