import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  device: null,
  useragent: null,
  orientation: null,
  width: null,
  isANewUser: false,
};

export const UserSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    defineDevice: (state, action) => {
      const { width, userAgent } = action.payload;
      state.width = width;
      const mql = window.matchMedia('(orientation: portrait)');
      if (mql.matches) {
        state.orientation = 'portrait';
      } else {
        state.orientation = 'landscape';
      }
      if (userAgent.includes('iPhone')) {
        state.useragent = 'iPhone';
      } else if (userAgent.includes('iPad')) {
        state.useragent = 'iPad';
      } else if (userAgent.includes('Android')) {
        state.useragent = 'Android';
      } else state.useragent = 'none';

      if (width <= 740) {
        state.device = 'isMobile';
      } else if (width > 740 && width <= 1180) {
        state.device = 'isTablet';
      } else {
        state.device = 'isDesktop';
      }
    },
    handleNewUser: (state, action) => {
      state.isANewUser = action.payload;
    },
  },
});

export const { defineDevice, handleNewUser } = UserSlice.actions;

export const deviceSelector = state => state.userinfo.device;
export const useragentSelector = state => state.userinfo.useragent;
export const orientationSelector = state => state.userinfo.orientation;
export const widthSelector = state => state.userinfo.width;
export const isANewUserSelector = state => state.userinfo.isANewUser;

export default UserSlice.reducer;
