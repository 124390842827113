import { useParams } from 'react-router-dom';
import Compass from '../../../shared/compass/Compass';
import VisualFlatPrice from '../VisualFlatPrice/VisualFlatPrice';
import BackLink from './BackLink/BackLink';
import ListLink from './ListLink/ListLink';
import NextLink from './NextLink/NextLink';
import style from './VisualBottomLinks.module.scss';

function VisualBottomLinks({
  visual,
  activeFloor,
  activeFlat,
  bulk,
  activeQueue,
  flatId,
  bottomLinksRef,
  flatData,
  priceRef,
}) {
  const { houseId, floorId, bulkId } = useParams();

  return (
    <>
      <div className={style.visualBottomLinks} ref={bottomLinksRef}>
        <ListLink />
        {!floorId && houseId && bulkId && <Compass bulk={bulk} />}
        <div className={style.navBox}>
          {floorId && <VisualFlatPrice visual={visual} flatData={flatData} priceRef={priceRef} />}
          <BackLink />
          <NextLink
            visual={visual}
            activeFloor={activeFloor}
            activeFlat={activeFlat}
            bulk={Number(bulk)}
            activeQueue={activeQueue}
            flatId={flatId}
          />
        </div>
      </div>
    </>
  );
}

export default VisualBottomLinks;
