import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import style from './RedPriceTooltip.module.scss';

export const RedPriceTooltip = ({ text, children, someStyles }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { pathname } = useLocation();

  const setHover = () => {
    if (isMobile && pathname.includes('/list')) return null;
    setShowTooltip(prevState => !prevState);
  };

  return (
    <div className={style.tooltipBox} onMouseEnter={setHover} onMouseLeave={setHover}>
      {children}
      {showTooltip && (
        <div className={style.wrapper + ' ' + someStyles}>
          <div className={style.tooltipContent}>
            <div className={style.tooltip}>
              <span>{text}</span>
            </div>
            <div className={style.topSquare} />
            <div className={style.bottomSquare} />
          </div>
        </div>
      )}
    </div>
  );
};
