import style from './MoreButton.module.scss';

function MoreButton({ buttonText, toggleHandler }) {
  return (
    <button onClick={toggleHandler} className={style.moreButton}>
      {buttonText}
    </button>
  );
}

export default MoreButton;
