export default function wordEndings(number, word) {
  if (!word) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return 'квартир';
    }
    n %= 10;
    if (n === 1) {
      return 'квартира';
    }
    if (n >= 2 && n <= 4) {
      return 'квартиры';
    }
    return 'квартир';
  }
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return number + ' ' + word;
  }
  n %= 10;
  if (n === 1) {
    return number + ' ' + word + 'a';
  }
  if (n >= 2 && n <= 4) {
    return number + ' ' + word + 'ы';
  }
  return 'Студия';
}
