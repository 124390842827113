import { useState } from 'react';

import whiteBoxMob from '../../assets/images/architecture_images/mobile/whiteBoxMob.jpg';
import whiteBoxMobWeb from '../../assets/images/architecture_images/mobile/whiteBoxMob.webp';
import Image from '../../shared/image/Image';
import ImageWrapperMob from '../../shared/image-wrapper-mob/ImageWrapperMob';
import SliderHand from '../../shared/slider-hand/SliderHand';
import { useUserService } from '../../tools/hooks/useUserService';
import { hideDataMobile, visibleData } from './dataWhiteBox';
import MoreButton from './more-button/MoreButton';
import style from './WhiteBoxMobile.module.scss';

function WhiteBoxMobile({ show = false }) {
  const [toggle, setToggle] = useState(false);

  const buttonText = toggle ? 'Скрыть' : 'Показать ещё';

  const { device, useragent, orientation } = useUserService();
  return (
    <div className={style.thirdScreen}>
      <div className={style.textBox}>
        <p className={style.title}>
          Самый
          <br /> спокойный
          <br /> переезд
        </p>
        <p className={style.text}>
          Все квартиры {!show && 'в башне 1-ой очереди'} сдаются с отделкой White Box. С момента покупки квартиры больше
          хочется наслаждаться тишиной и пением птиц, чем строительной пылью и трелью перфоратора.
          <br /> <br />
          Черновые работы останутся позади, а вам останется самая увлекательная часть — выбрать финишные материалы и
          декорировать ваш будущий дом по своему вкусу.
        </p>
      </div>
      <div className={style.bgBox}>
        <ImageWrapperMob>
          <Image src375={whiteBoxMob} src375Web={whiteBoxMobWeb} imagePlug={whiteBoxMob} />
        </ImageWrapperMob>
        <p className={style.bgDescription}>Отделка White Box</p>
      </div>

      <div className={style.cardsSection}>
        <p className={style.subtitle}>В отделку включено</p>
        {(((device === 'isMobile' || device === 'isTablet') && orientation === 'portrait') ||
          ((device === 'isMobile' || device === 'isTablet') &&
            orientation === 'landscape' &&
            useragent === 'none')) && <SliderHand orientation='right' />}
      </div>

      <div className={style.cardBox}>
        {visibleData.map(item => {
          return (
            <div key={item.id} className={style.card}>
              <img className={style.icon} src={item.icon} alt='icon' />
              <p className={style.description}>{item.description}</p>
            </div>
          );
        })}
      </div>

      {!show && (
        <>
          {toggle && (
            <div className={style.hiddenSection}>
              {hideDataMobile.map(item => {
                return (
                  <ul key={item.id} className={style.hiddenCard}>
                    <li className={style.hiddenDescription}>{item.description}</li>
                  </ul>
                );
              })}
            </div>
          )}

          <div className={style.buttonBox}>
            <MoreButton
              buttonText={buttonText}
              toggleHandler={() => {
                setToggle(!toggle);
              }}
            />
          </div>
        </>
      )}

      {show && (
        <div className={style.hiddenSection}>
          {hideDataMobile.map(item => {
            return (
              <ul key={item.id} className={style.hiddenCard}>
                <li className={style.hiddenDescription}>{item.description}</li>
              </ul>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default WhiteBoxMobile;
