const ROUTES = {
  root: '/',
  privacy: '/policy',
  cashback: '/cashback_conditions',
  booking: '/booking',
  favorites: {
    root: '/favorites/*',
    plainRoot: '/favorites/',
    flats: 'flats',
    parking: 'parking',
    keller: 'keller',
  },
  visual: '/flats/visual',
  list: '/flats/list',
  flats: {
    root: '/flats',
    list: '/list',
    visual: {
      root: '/visual',
      house: '/visual/:houseId',
      bulk: '/visual/:houseId/:bulkId',
      floor: '/visual/:houseId/:bulkId/:floorId',
    },
    flat: '/flat/:flatId',
  },
  about: '/about',

  contacts: '/contacts',
  team: '/team',
  documents: '/documents',
  purchaseTerms: {
    root: '/purchase-terms/*',
    plainRoot: '/purchase-terms/',
    fullPayment: 'full',
    installmentPlan: 'installment',
    mortgage: 'mortgage',
    tradeIn: 'trade-in',
    onlinePurchase: 'online',
    buyout: 'buyout',
    cashback: 'cashback',
  },
  news: {
    root: '/news',
    slug: '/news/:slug',
  },
  promo: {
    root: '/promo',
    slug: '/promo/:slug',
  },
  menuList: [
    {
      name: 'Документы',
      route: '/documents',
    },
    {
      name: 'Динамика строительства',
      route: '/progress',
    },
    {
      name: 'Контакты',
      route: '/contacts',
    },
    {
      name: 'Команда проекта',
      route: '/team',
    },
    {
      name: 'Новости',
      route: '/news',
    },
    {
      name: 'Акции',
      route: '/promo',
    },
    {
      name: 'Условия покупки',
      route: '/purchase-terms/mortgage',
    },
  ],
  progress: {
    root: '/progress',
    slug: '/progress/:slug',
  },
  landscaping: '/landscaping',
  location: '/location',
  infrastructure: '/infrastructure',
  commercial: '/commercial',
  gallery: '/gallery',
  whitebox: '/whitebox',
  map: '/sitemap',
  parking: '/parking',
  serverError: '/502',
  school: '/school',
};

export default ROUTES;
