import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchDocuments = createAsyncThunk('documentsSlice/fetchDocuments', async (_, thunkApi) => {
  return fetchData(api.urlDocuments, thunkApi);
});

const initialState = {
  documents: null,
  fetchError: false,
};

export const documentsSlice = createSlice({
  name: 'documentsInfo',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload;
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const documentsSelector = state => state.documentsInfo.documents;

export const fetchErrorSelector = state => state.documentsInfo.fetchError;

export default documentsSlice.reducer;
