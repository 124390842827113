import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parkingTypes: [
    { id: 1, name: 'Все', active: true },
    { id: 2, name: 'Стандартное', active: false },
    { id: 3, name: 'Семейное', active: false },
  ],
  sliderValues: ['1.00', '30.00'],
  characteristics: [
    {
      id: 1,
      name: 'Одноуровневая отапливаемая подземная автостоянка на&nbsp;70&nbsp;машино-мест, из&nbsp;которых 12&nbsp;зависимых (семейных).',
    },
    {
      id: 2,
      name: 'На&nbsp;подземном этаже расположено 11&nbsp;келлеров&nbsp;&mdash; это личные помещения, в&nbsp;которых жители MOMENTS хранят автомобильные колёса, спортинвентарь и&nbsp;другие сезонные вещи.',
    },
    {
      id: 3,
      name: 'Въезд/выезд осуществляется через скоростные секционные ворота по&nbsp;однопутной рампе, с&nbsp;3-го Красногорского проезда.',
    },
    {
      id: 4,
      name: 'На&nbsp;рампе предусмотрено устройство реверсивного движения, установлены светофоры на&nbsp;въезде/выезде.',
    },
    {
      id: 5,
      name: 'Доступ жильцов на&nbsp;паркинг возможен по&nbsp;считывателю карт, с&nbsp;помощью бесконтактного считывателя (метки) и&nbsp;камерами с&nbsp;определением автомобильных номеров.',
    },
    {
      id: 6,
      name: 'Основные зоны паркинга оборудованы видеонаблюдением, предусмотрен усилитель сигнала сотовой связи и&nbsp;wi-fi.',
    },
    { id: 7, name: 'Для удобства жильцов доступ на&nbsp;подземный этаж обеспечивается всеми лифтами жилой секции.' },
    {
      id: 8,
      name: 'Также паркинг оборудован прямым выходом на&nbsp;улицу через лестницы или через пешеходный путь по&nbsp;рампе.',
    },
    { id: 9, name: 'Высота подземного этажа переменная&nbsp;&mdash; от&nbsp;3,75&nbsp;м до&nbsp;5,5&nbsp;м.' },
  ],
  view: [
    { id: 1, name: 'На схеме', active: true, disabled: false },
    { id: 2, name: 'Списком', active: false, disabled: false },
  ],
  filters: [
    { id: 1, name: 'Номер', filterable: true, active: false, sorting: 'asc' },
    { id: 2, name: 'Площадь', filterable: true, active: false, sorting: 'asc' },
    { id: 3, name: 'Тип', filterable: false, active: false, sorting: 'asc' },
    { id: 4, name: 'Срок сдачи', filterable: true, active: false, sorting: 'asc' },
    { id: 5, name: 'Стоимость', filterable: true, active: true, sorting: 'asc' },
  ],
  mobSort: [
    { id: 1, title: 'Цена по возрастанию', active: true },
    { id: 2, title: 'Цена по убыванию', active: false },
    { id: 3, title: 'Площадь по возрастанию', active: false },
    { id: 4, title: 'Площадь по убыванию', active: false },
  ],
  initialParking: [
    {
      id: 1,
      number: 2,
      area: 65.43,
      type: 'Стандартное',
      releaseDate: 'II кв 2024, II очередь',
      date: 2024,
      priceText: '2 000 940 ₽',
      price: 2000940,
      active: false,
    },
    {
      id: 2,
      number: 29,
      area: 72.28,
      type: 'Стандартное',
      releaseDate: 'II кв 2023, I очередь',
      date: 2023,
      priceText: '5 302 280 ₽',
      price: 5302280,
      active: false,
    },
    {
      id: 3,
      number: 49,
      area: 100.46,
      type: 'Семейное',
      releaseDate: 'II кв 2024, II очередь',
      date: 2024,
      priceText: '24 902 740 ₽',
      price: 24902740,
      active: false,
    },
    {
      id: 4,
      number: 67,
      area: 63.26,
      type: 'Стандартное',
      releaseDate: 'II кв 2023, I очередь',
      date: 2023,
      priceText: '9 850 550 ₽',
      price: 9850550,
      active: false,
    },
    {
      id: 5,
      number: 72,
      area: 112.37,
      type: 'Семейное',
      releaseDate: 'II кв 2023, I очередь',
      date: 2023,
      priceText: '28 180 520 ₽',
      price: 28180520,
      active: false,
    },
  ],
  filteredParking: null,
  parking: null,
  activeParking: null,
  sortingOrder: 'asc',
};

export const parkingSlice = createSlice({
  name: 'parkingSlice',
  initialState,
  reducers: {
    changeActiveParking: (state, action) => {
      state.activeParking = action.payload;
    },
    changeParkingType: (state, action) => {
      state.parkingTypes = state.parkingTypes.map(item =>
        item.id === action.payload.id ? { ...item, active: action.payload.active } : { ...item, active: false },
      );
    },
    changeView: (state, action) => {
      state.view = state.view.map(item =>
        item.id === action.payload.id ? { ...item, active: action.payload.active } : { ...item, active: false },
      );
    },
    changePlaces: (state, action) => {
      if (state.parking) {
        state.parking = state.parking.map(item =>
          item.id === action.payload.id ? { ...item, active: action.payload.active } : { ...item, active: false },
        );
      } else {
        state.initialParking = state.initialParking.map(item =>
          item.id === action.payload.id ? { ...item, active: action.payload.active } : { ...item, active: false },
        );
      }
    },
    updateParkingSliderValues: (state, action) => {
      state.sliderValues = action.payload;
    },
    setActualParking: (state, action) => {
      state.parking = state.filteredParking;
    },
    filterByType: (state, action) => {
      if (action.payload !== 'Все')
        state.filteredParking = state.initialParking.filter(
          item =>
            item.type === action.payload &&
            item.price / 1000000 <= Number(state.sliderValues[1]) &&
            item.price / 1000000 >= Number(state.sliderValues[0]),
        );
      if (action.payload === 'Все')
        state.filteredParking = state.initialParking.filter(
          item =>
            item.price / 1000000 <= Number(state.sliderValues[1]) &&
            item.price / 1000000 >= Number(state.sliderValues[0]),
        );
      const activeFilter = state.filters.find(item => item.active === true);

      switch (activeFilter.id + '-' + state.sortingOrder) {
        case '1-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.number - b.number);
          break;
        case '1-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.number - a.number);
          break;
        case '2-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.area - b.area);
          break;
        case '2-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.area - a.area);
          break;
        case '4-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.date - b.date);
          break;
        case '4-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.date - a.date);
          break;
        case '5-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.price - b.price);
          break;
        case '5-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.price - a.price);
          break;
        default:
          break;
      }
    },

    setSortingOrder: (state, action) => {
      state.sortingOrder = action.payload;
    },

    filterByPrice: (state, action) => {
      if (action.payload !== 'Все')
        state.filteredParking = state.initialParking.filter(
          item =>
            item.type === action.payload &&
            item.price / 1000000 <= Number(state.sliderValues[1]) &&
            item.price / 1000000 >= Number(state.sliderValues[0]),
        );
      if (action.payload === 'Все')
        state.filteredParking = state.initialParking.filter(
          item =>
            item.price / 1000000 <= Number(state.sliderValues[1]) &&
            item.price / 1000000 >= Number(state.sliderValues[0]),
        );

      const activeFilter = state.filters.find(item => item.active === true);

      switch (activeFilter.id + '-' + state.sortingOrder) {
        case '1-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.number - b.number);
          break;
        case '1-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.number - a.number);
          break;
        case '2-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.area - b.area);
          break;
        case '2-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.area - a.area);
          break;
        case '4-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.date - b.date);
          break;
        case '4-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.date - a.date);
          break;
        case '5-asc':
          state.filteredParking = state.filteredParking.sort((a, b) => a.price - b.price);
          break;
        case '5-desc':
          state.filteredParking = state.filteredParking.sort((a, b) => b.price - a.price);
          break;
        default:
          break;
      }
    },
    resetParkingFilters: (state, action) => {
      state.parking = null;
      state.filteredParking = null;
      state.parkingTypes = state.parkingTypes.map(item =>
        item.id === 1 ? { ...item, active: true } : { ...item, active: false },
      );
      state.sliderValues = [1, 30];
      state.filters = state.filters.map(item =>
        item.id === 5 ? { ...item, active: true } : { ...item, active: false },
      );
    },
    changeMobSort: (state, action) => {
      state.mobSort = state.mobSort.map(item =>
        item.id === action.payload.id ? { ...item, active: true } : { ...item, active: false },
      );
      if (state.filteredParking !== null) {
        switch (action.payload.id) {
          case 1:
            state.filteredParking = state.filteredParking.sort((a, b) => a.price - b.price);
            break;
          case 2:
            state.filteredParking = state.filteredParking.sort((a, b) => b.price - a.price);
            break;
          case 3:
            state.filteredParking = state.filteredParking.sort((a, b) => a.area - b.area);
            break;
          case 4:
            state.filteredParking = state.filteredParking.sort((a, b) => b.area - a.area);
            break;
          default:
            break;
        }
      } else {
        switch (action.payload.id) {
          case 1:
            state.filteredParking = state.initialParking.sort((a, b) => a.price - b.price);
            break;
          case 2:
            state.filteredParking = state.initialParking.sort((a, b) => b.price - a.price);
            break;
          case 3:
            state.filteredParking = state.initialParking.sort((a, b) => a.area - b.area);
            break;
          case 4:
            state.filteredParking = state.initialParking.sort((a, b) => b.area - a.area);
            break;
          default:
            break;
        }
      }
    },
    sortBy: (state, action) => {
      state.filters = state.filters.map(item =>
        item.id === action.payload.id
          ? { ...item, active: true, sorting: action.payload.order }
          : { ...item, active: false },
      );
      if (state.filteredParking !== null) {
        switch (action.payload.id + '-' + action.payload.order) {
          case '1-asc':
            state.filteredParking = state.filteredParking.sort((a, b) => a.number - b.number);
            break;
          case '1-desc':
            state.filteredParking = state.filteredParking.sort((a, b) => b.number - a.number);
            break;
          case '2-asc':
            state.filteredParking = state.filteredParking.sort((a, b) => a.area - b.area);
            break;
          case '2-desc':
            state.filteredParking = state.filteredParking.sort((a, b) => b.area - a.area);
            break;
          case '4-asc':
            state.filteredParking = state.filteredParking.sort((a, b) => a.date - b.date);
            break;
          case '4-desc':
            state.filteredParking = state.filteredParking.sort((a, b) => b.date - a.date);
            break;
          case '5-asc':
            state.filteredParking = state.filteredParking.sort((a, b) => a.price - b.price);
            break;
          case '5-desc':
            state.filteredParking = state.filteredParking.sort((a, b) => b.price - a.price);
            break;
          default:
            break;
        }
      } else {
        switch (action.payload.id + '-' + action.payload.order) {
          case '1-asc':
            state.filteredParking = state.initialParking.sort((a, b) => a.number - b.number);
            break;
          case '1-desc':
            state.filteredParking = state.initialParking.sort((a, b) => b.number - a.number);
            break;
          case '2-asc':
            state.filteredParking = state.initialParking.sort((a, b) => a.area - b.area);
            break;
          case '2-desc':
            state.filteredParking = state.initialParking.sort((a, b) => b.area - a.area);
            break;
          case '4-asc':
            state.filteredParking = state.initialParking.sort((a, b) => a.date - b.date);
            break;
          case '4-desc':
            state.filteredParking = state.initialParking.sort((a, b) => b.date - a.date);
            break;
          case '5-asc':
            state.filteredParking = state.initialParking.sort((a, b) => a.price - b.price);
            break;
          case '5-desc':
            state.filteredParking = state.initialParking.sort((a, b) => b.price - a.price);
            break;
          default:
            break;
        }
      }
    },
  },
});

export const {
  changeParkingType,
  updateParkingSliderValues,
  filterByType,
  setActualParking,
  filterByPrice,
  resetParkingFilters,
  sortBy,
  changeMobSort,
  changeView,
  changePlaces,
  changeActiveParking,
  setSortingOrder,
} = parkingSlice.actions;

export const parkingTypesSelector = state => state.parking.parkingTypes;
export const sliderValuesSelector = state => state.parking.sliderValues;
export const characteristicsSelector = state => state.parking.characteristics;
export const parkingViewSelector = state => state.parking.view;
export const parkingFiltersSelector = state => state.parking.filters;
export const initialParkingSelector = state => state.parking.initialParking;
export const parkingSelector = state => state.parking.parking;
export const filteredParkingSelector = state => state.parking.filteredParking;
export const mobSortSelector = state => state.parking.mobSort;
export const activeParkingSelector = state => state.parking.activeParking;
export const sortingOrderSelector = state => state.parking.sortingOrder;

export default parkingSlice.reducer;
