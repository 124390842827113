import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Img2_jpg from '../assets/images/plans_images/plan_1.jpg';
import Img2 from '../assets/images/plans_images/plan_1.webp';
import Plug1 from '../assets/images/plans_images/plan_1_plug.jpg';
import Img3_jpg from '../assets/images/plans_images/plan_2.jpg';
import Img3 from '../assets/images/plans_images/plan_2.webp';
import Plug2 from '../assets/images/plans_images/plan_2_plug.jpg';
import Img4_jpg from '../assets/images/plans_images/plan_3.jpg';
import Img4 from '../assets/images/plans_images/plan_3.webp';
import Plug3 from '../assets/images/plans_images/plan_3_plug.jpg';
import Img5_jpg from '../assets/images/plans_images/plan_4.jpg';
import Img5 from '../assets/images/plans_images/plan_4.webp';
import Plug4 from '../assets/images/plans_images/plan_4_plug.jpg';
import Img11_jpg from '../assets/images/plans_images/plan_5.jpg';
import Img11 from '../assets/images/plans_images/plan_5.webp';
import Plug5 from '../assets/images/plans_images/plan_5_plug.jpg';
import Img1_jpg from '../assets/images/slider_images/slider_1.jpg';
import Img1 from '../assets/images/slider_images/slider_1.webp';
import Img1_jpg_mob from '../assets/images/slider_images/slider_1_mob.jpg';
import Img1_mob from '../assets/images/slider_images/slider_1_mob.webp';
import imgPlugSlider1 from '../assets/images/slider_images/slider_1_plug.jpg';
import Img6_jpg from '../assets/images/slider_images/slider_2.jpg';
import Img6 from '../assets/images/slider_images/slider_2.webp';
import Img6_jpg_mob from '../assets/images/slider_images/slider_2_mob.jpg';
import Img6_mob from '../assets/images/slider_images/slider_2_mob.webp';
import imgPlugSlider2 from '../assets/images/slider_images/slider_2_plug.jpg';
import Img7_jpg from '../assets/images/slider_images/slider_3.jpg';
import Img7 from '../assets/images/slider_images/slider_3.webp';
import Img7_jpg_mob from '../assets/images/slider_images/slider_3_mob.jpg';
import Img7_mob from '../assets/images/slider_images/slider_3_mob.webp';
import imgPlugSlider3 from '../assets/images/slider_images/slider_3_plug.jpg';
import Img8_jpg from '../assets/images/slider_images/slider_4.jpg';
import Img8 from '../assets/images/slider_images/slider_4.webp';
import Img8_jpg_mob from '../assets/images/slider_images/slider_4_mob.jpg';
import Img8_mob from '../assets/images/slider_images/slider_4_mob.webp';
import imgPlugSlider4 from '../assets/images/slider_images/slider_4_plug.jpg';
import Img9_jpg from '../assets/images/slider_images/slider_5.jpg';
import Img9 from '../assets/images/slider_images/slider_5.webp';
import Img9_jpg_mob from '../assets/images/slider_images/slider_5_mob.jpg';
import Img9_mob from '../assets/images/slider_images/slider_5_mob.webp';
import imgPlugSlider5 from '../assets/images/slider_images/slider_5_plug.jpg';
import Img10_jpg from '../assets/images/slider_images/slider_6.jpg';
import Img10 from '../assets/images/slider_images/slider_6.webp';
import Img10_jpg_mob from '../assets/images/slider_images/slider_6_mob.jpg';
import Img10_mob from '../assets/images/slider_images/slider_6_mob.webp';
import imgPlugSlider6 from '../assets/images/slider_images/slider_6_plug.jpg';
import Img2_xsj from '../assets/mobile_images_gen/plans_images/plan_1.jpg';
import Img2xs from '../assets/mobile_images_gen/plans_images/plan_1.webp';
import Img3_xsj from '../assets/mobile_images_gen/plans_images/plan_2.jpg';
import Img3xs from '../assets/mobile_images_gen/plans_images/plan_2.webp';
import Img4_xsj from '../assets/mobile_images_gen/plans_images/plan_3.jpg';
import Img4xs from '../assets/mobile_images_gen/plans_images/plan_3.webp';
import Img5_xsj from '../assets/mobile_images_gen/plans_images/plan_4.jpg';
import Img5xs from '../assets/mobile_images_gen/plans_images/plan_4.webp';
import Img11_xsj from '../assets/mobile_images_gen/plans_images/plan_5.jpg';
import Img11xs from '../assets/mobile_images_gen/plans_images/plan_5.webp';
import PanoramaEvening from '../assets/panorama/1_full.webp';
import PanoramaMorning from '../assets/panorama/2_full.webp';
import PanoramaAfternoon from '../assets/panorama/3_full.webp';
import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchMenuLeft = createAsyncThunk('projectSlice/fetchMenuLeft', async (_, thunkApi) => {
  return fetchData(api.urlMenuLeft, thunkApi);
});

export const fetchMenuRight = createAsyncThunk('projectSlice/fetchMenuRight', async (_, thunkApi) => {
  return fetchData(api.urlMenuRight, thunkApi);
});

export const fetchFooterLeft = createAsyncThunk('projectSlice/fetchFooterLeft', async (_, thunkApi) => {
  return fetchData(api.urlFooterLeft, thunkApi);
});

export const fetchFooterMiddle = createAsyncThunk('projectSlice/fetchFooterMiddle', async (_, thunkApi) => {
  return fetchData(api.urlFooterMiddle, thunkApi);
});

export const fetchFooterRight = createAsyncThunk('projectSlice/fetchFooterRight', async (_, thunkApi) => {
  return fetchData(api.urlFooterRight, thunkApi);
});

export const fetchPromoCards = createAsyncThunk('projectSclice/fetchPromoCards', async (_, thunkApi) => {
  return fetchData(api.urlPromoCards, thunkApi);
});

export const fetchBulks = createAsyncThunk('projectSlice/fetchBulks', async (_, thunkApi) => {
  return fetchData(api.urlBulks, thunkApi);
});

export const fetchGeneralSettings = createAsyncThunk('projectSlice/fetchGeneralSettings', async (_, thunkApi) => {
  return fetchData(api.urlGeneralSettings, thunkApi);
});

const initialState = {
  slider: [
    {
      id: 0,
      title: '<p>Наслаждайтесь</p><p>прогулкой во дворе</p>',
      subTitle: 'Таймлайн незабываемых моментов',
      img: Img1_jpg,
      srcWebP: Img1,
      imgMob: Img1_jpg_mob,
      srcWebPmob: Img1_mob,
      description:
        'Иммерсивное благоустройство от&nbsp;британского бюро Gillespies сфокусировано на&nbsp;эмоциональном и&nbsp;чувственном опыте. Каждая прогулка по&nbsp;Moments погружает в&nbsp;путешествие со&nbsp;сменой сценариев и&nbsp;плавным потоком уникальных моментов.',
      active: true,
      plug: imgPlugSlider1,
    },
    {
      id: 1,
      title: '<p>Наслаждайтесь</p><p>прогулкой в парке</p>',
      subTitle: 'Везде есть место для игp',
      img: Img6_jpg,
      srcWebP: Img6,
      imgMob: Img6_jpg_mob,
      srcWebPmob: Img6_mob,
      description:
        'Игровые элементы из натуральных материалов интегрированы в насыщенный эколандшафт. Для малышей предусмотрены тихие зелёные площадки у дома. Для подростков — парк активностей с полосой препятствий, скейт-спотом и скалодромом.',
      active: false,
      plug: imgPlugSlider2,
    },
    {
      id: 2,
      title: '<p>Наслаждайтесь</p><p>прогулкой в городе</p>',
      subTitle: 'В школу и сад с удовольствием',
      img: Img7_jpg,
      srcWebP: Img7,
      imgMob: Img7_jpg_mob,
      srcWebPmob: Img7_mob,
      description:
        'Своя школа на&nbsp;350 мест и&nbsp;детский сад на&nbsp;175 мест вдохновляют учиться и&nbsp;познавать мир через его красоту. Лужайки и&nbsp;холмы для отдыха, классы на&nbsp;свежем воздухе, развивающие площадки и&nbsp;сенсорные сады, современное спортивное оборудование, поле для командных игр и&nbsp;матчей.',
      active: false,
      plug: imgPlugSlider3,
    },
    {
      id: 3,
      title: '<p>Наслаждайтесь</p><p>прогулкой во дворе</p>',
      subTitle: 'Всё, чтобы не покидать Moments',
      img: Img8_jpg,
      srcWebP: Img8,
      imgMob: Img8_jpg_mob,
      srcWebPmob: Img8_mob,
      description:
        'Сбалансированная инфраструктура позволит планировать свой день и&nbsp;вычёркивать списки дел без суеты. Супермаркет, бьюти-салон, аптека и&nbsp;прочие полезности на&nbsp;первых этажах. Кофейни, пекарни, ресторанчики и&nbsp;семейные кафе вдоль прогулочных зон.',
      active: false,
      plug: imgPlugSlider4,
    },
    {
      id: 4,
      title: '<p>Наслаждайтесь</p><p>прогулкой во дворе</p>',
      subTitle: 'Лобби для релакса и работы',
      img: Img9_jpg,
      srcWebP: Img9,
      imgMob: Img9_jpg_mob,
      srcWebPmob: Img9_mob,
      description:
        'В&nbsp;просторных лобби с&nbsp;панорамными видами на&nbsp;пышный сад всё продумано до&nbsp;мелочей: гостеприимные лаундж-зоны с&nbsp;мягкой мебелью, уютный open space коворкинг, ресепшн, почтовая и&nbsp;посылочная, гостевые санузлы, удобный лифтовый холл, лапомойка и&nbsp;колясочная для мам с маленькими детьми.',
      active: false,
      plug: imgPlugSlider5,
    },
    {
      id: 5,
      title: '<p>Наслаждайтесь</p><p>прогулкой во дворе</p>',
      subTitle: 'Место для авто и больших вещей      ',
      img: Img10_jpg,
      srcWebP: Img10,
      imgMob: Img10_jpg_mob,
      srcWebPmob: Img10_mob,
      description:
        'Сезонные вещи, велосипеды, самокаты, сноуборды, вейк или сап-борд смогут поселиться во&nbsp;вместительных келлерах. Лифт от&nbsp;квартиры моментально доставит в&nbsp;тёплый подземный паркинг. Дождь, сугробы, поиск свободного места&nbsp;&mdash; об&nbsp;этом в&nbsp;Moments можно не&nbsp;волноваться.',
      active: false,
      plug: imgPlugSlider6,
    },
  ],

  roomsInfo: [
    {
      id: 0,
      img: Img5_jpg,
      srcWebP: Img5,
      plug: Plug4,
      imgMob: Img5_xsj,
      srcWebPmob: Img5xs,
      title: 'Студии',
      area: '25,78',
      active: true,
      description:
        'Сбалансированные студии с&nbsp;полноценной ванной и&nbsp;балконом. Здесь достаточно места, чтобы устроить тёплую ламповую вечеринку для самых близких друзей.',
    },
    {
      id: 1,
      plug: Plug3,
      img: Img4_jpg,
      srcWebP: Img4,
      imgMob: Img4_xsj,
      srcWebPmob: Img4xs,
      title: '1-спальные',
      area: '37,75',
      active: false,
      description:
        'Необычные односпальные квартиры с&nbsp;двумя санузлами. Гостиная евро-формата плюс отдельная мастер-спальня&nbsp;&mdash; проверенная формула для комфортной жизни вдвоем.',
    },
    {
      id: 2,
      plug: Plug1,
      img: Img2_jpg,
      srcWebP: Img2,
      imgMob: Img2_xsj,
      srcWebPmob: Img2xs,
      title: '2-спальные',
      area: '61,01',
      active: false,
      description:
        'Ещё больше личного пространства и&nbsp;ежедневного комфорта, который оценят как дети, так и&nbsp;родители. Угловые квартиры подарят дополнительное окно в&nbsp;спальне или детской.',
    },
    {
      id: 3,
      plug: Plug5,
      img: Img11_jpg,
      srcWebP: Img11,
      imgMob: Img11_xsj,
      srcWebPmob: Img11xs,
      title: '3-спальные',
      area: '82,28',
      active: false,
      description:
        'Мастер-спальня с&nbsp;отдельным санузлом и&nbsp;гардеробной. Своё пространство для старших и&nbsp;младших. Большая гостиная для дружных семейных обедов и&nbsp;кинопросмотров. Любимые бабушки и&nbsp;дедушки смогут свободно у&nbsp;вас погостить.',
    },
    {
      id: 4,
      plug: Plug2,
      img: Img3_jpg,
      srcWebP: Img3,
      imgMob: Img3_xsj,
      srcWebPmob: Img3xs,
      title: '4-спальные',
      area: '113,93',
      active: false,
      description:
        'Самая настоящая семейная резиденция. По&nbsp;два окна в&nbsp;спальне и&nbsp;в&nbsp;гостиной обеспечат максимальное удовольствие от&nbsp;видов и&nbsp;полную свободу для интересных интерьерных решений. Уединённая мастер-спальня с&nbsp;гардеробной и&nbsp;три санузла&nbsp;&mdash; домашним удобно, гостям понравится.',
    },
  ],

  panoramas: [
    {
      id: 0,
      title: 'утро',
      img: PanoramaMorning,
      imgMob: PanoramaMorning,
      active: true,
    },
    {
      id: 1,
      title: 'день',
      img: PanoramaAfternoon,
      imgMob: PanoramaAfternoon,
      active: false,
    },
    {
      id: 2,
      title: 'вечер',
      img: PanoramaEvening,
      imgMob: PanoramaEvening,
      active: false,
    },
  ],
  contacts: {
    phone: '+7 (495) 104-71-24',
    mail: 'SALES@FORMA.RU',
    address: 'Москва, 2-ой Красногорский проезд',
    time: 'Ежедневно с 9:00 до 21:00',
  },
  flat: {
    deadline: 'Срок сдачи 31 июля 2024',
    listDeadline: 'II кв 2023',
    quarter: 'Квартал Некрасовка',
  },
  menuLeft: null,
  menuRight: null,
  footerLeft: null,
  footerRight: null,
  footerMiddle: null,
  fetchError: false,
  promoCards: null,
  generalSettings: null,
  bulks: null,
};

export const ProjectSlice = createSlice({
  name: 'projectinfo',
  initialState,
  reducers: {
    changeRoomsAreas: (state, action) => {
      state.roomsInfo = state.roomsInfo.map(item => ({
        ...item,
        area: action.payload[item.id] !== 0 ? action.payload[item.id] : item.area,
      }));
    },
    changeActiveSlide: (state, action) => {
      const newState = state.slider.map(item => {
        if (item.id === action.payload) {
          item.active = true;
        } else item.active = false;
        return item;
      });
      state.slider = newState;
    },

    changeActiveRooms: (state, action) => {
      const newState = state.roomsInfo.map(item => {
        if (item.id === action.payload) {
          item.active = true;
        } else item.active = false;
        return item;
      });

      state.roomsInfo = newState;
    },

    changeActivePanorama: (state, action) => {
      const newState = state.panoramas.map(item => {
        if (item.id === action.payload) {
          item.active = true;
        } else item.active = false;
        return item;
      });

      state.panoramas = newState;
    },
  },
  extraReducers: {
    [fetchMenuLeft.fulfilled]: (state, action) => {
      state.menuLeft = action.payload;
    },
    [fetchMenuLeft.rejected]: (state, action) => {
      state.fetchError = true;
    },
    [fetchMenuRight.fulfilled]: (state, action) => {
      state.menuRight = action.payload;
    },
    [fetchMenuRight.rejected]: (state, action) => {
      state.fetchError = true;
    },
    [fetchFooterRight.fulfilled]: (state, action) => {
      state.footerRight = action.payload;
    },
    [fetchFooterRight.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchFooterMiddle.fulfilled]: (state, action) => {
      state.footerMiddle = action.payload;
    },
    [fetchFooterMiddle.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchFooterLeft.fulfilled]: (state, action) => {
      state.footerLeft = action.payload;
    },
    [fetchFooterLeft.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchPromoCards.fulfilled]: (state, action) => {
      state.promoCards = action.payload;
    },
    [fetchPromoCards.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchGeneralSettings.fulfilled]: (state, action) => {
      state.generalSettings = action.payload;
    },
    [fetchGeneralSettings.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchBulks.fulfilled]: (state, action) => {
      state.bulks = action.payload;
    },
    [fetchBulks.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { changeActiveSlide, changeActiveRooms, changeActivePanorama, changeRoomsAreas } = ProjectSlice.actions;

export const menuLeftSelector = state => state.projectinfo.menuLeft;
export const menuRightSelector = state => state.projectinfo.menuRight;
export const footerLeftSelector = state => state.projectinfo.footerLeft;
export const footerMiddleSelector = state => state.projectinfo.footerMiddle;
export const footerRightSelector = state => state.projectinfo.footerRight;
export const fetchErrorSelector = state => state.projectinfo.fetchError;
export const promoCardsSelector = state => state.projectinfo.promoCards;
export const bulksSelector = state => state.projectinfo.bulks;
export const generalSettingsSelector = state => state.projectinfo.generalSettings;

export default ProjectSlice.reducer;
