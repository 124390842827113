import { useEffect } from 'react';

const getData = event => {
  const values = event.detail.object.values();

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const comagicData = getCookie('cm_data');

  const data = {
    email: values.LEAD_EMAIL[0],
    phone: values.LEAD_PHONE[0],
    cm_data: comagicData ? comagicData : '',
    formName: 'Чат на сайте',
  };

  if (values?.LEAD_NAME[0] || values?.LEAD_LAST_NAME[0]) {
    data.name = (values?.LEAD_NAME[0] || '') + ' ' + (values?.LEAD_LAST_NAME[0] || '');
  }

  return data;
};

const useBitrixChatFormEffect = () => {
  useEffect(() => {
    window.addEventListener('b24:form:send:success', async event => {
      const url = 'https://comagic.keep-calm.ru/api/5mjbi0kthbedfjgvzsu06swtfhrw7nscdsvya625';
      const filteredData = {};
      const data = getData(event);

      for (const key in data) {
        if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
          filteredData[key] = data[key];
        }
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filteredData),
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    });
  }, []);
};

export default useBitrixChatFormEffect;
