import { Link } from 'react-router-dom';
import style from './BreadCrumbs.module.scss';

// !!! Снизу инструкция !!! //

const LinkComponent = ({ item, color, crumbs }) => {
  const i = item[0];
  const index = item[1];

  return (
    <>
      <li itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem' style={{ listStyleType: 'none' }}>
        <Link key={i.id} className={style.link} to={i.link} style={{ color }} title={i.text} itemProp='item'>
          <span itemProp='name'>{i.text}</span>
          <meta itemProp='position' content={Number(i.id) - 1} />
        </Link>
      </li>
      {index !== crumbs.length - 1 && (
        <div className={style.dotWrapper}>
          <div style={{ backgroundColor: color }} className={style.dot} />
        </div>
      )}
    </>
  );
};

const TextComponent = ({ item, color }) => {
  return (
    <li itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem' className={style.textComponent}>
      <span key={item.id} className={style.item} style={{ color }} itemProp='name'>
        {item.text}
      </span>
      <meta itemProp='position' content={Number(item.id) - 1} />
    </li>
  );
};

const BreadCrumbs = ({ item }) => {
  const { color = '#FFFFFF', crumbs = [] } = item;

  return (
    <ul className={style.content} itemScope itemType='https://schema.org/BreadcrumbList'>
      {crumbs.map((i, index) => {
        return i.link ? (
          <LinkComponent key={i.id} item={[i, index]} color={color} crumbs={crumbs} />
        ) : (
          <TextComponent key={i.id} item={i} color={color} />
        );
      })}
    </ul>
  );
};

export default BreadCrumbs;

// Пример передаваемого объекта
// Поле color не обязательное, если не передать то по дефолту будет белый #FFFFFF
// Если не передать crumbs то будет пустой массив
// Если не передать link то будет просто текст

// const breadCrumbsApi = {
//	color: '#646464'
// 	crumbs: [
// 		{
// 			id: 1,
// 			text: 'Главная',
// 			link: ROUTES.root
// 		},
// 		{
// 			id: 2,
// 			text: 'Команда проекта'
// 		}
// 	]
// }
