import { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { handleHeaderColorScheme } from '../../entities/ComponentsSlice';
import { deviceSelector } from '../../entities/UserSlice';
import useScroll from '../hooks/useScroll';

const ScrollHelperBlackTrans = () => {
  const dispatch = useDispatch();
  const { scroll } = useLocomotiveScroll();
  const { scrollY } = useScroll();
  const device = useSelector(deviceSelector);

  useEffect(() => {
    dispatch(handleHeaderColorScheme('blackOnTransparentTwo'));
    return function () {
      dispatch(handleHeaderColorScheme(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device === 'isMobile' || !scroll) {
      dispatch(handleHeaderColorScheme(''));
    }

    if (scroll) {
      scroll.on('scroll', () => {
        if (scroll.scroll.instance.scroll.y < 50) {
          dispatch(handleHeaderColorScheme('blackOnTransparentTwo'));
        } else {
          dispatch(handleHeaderColorScheme(''));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, scrollY]);

  return null;
};

export default ScrollHelperBlackTrans;
