export const addCurrentPrice = array => {
  const arr = [];

  for (const element of array) {
    if (element.mainBenefit?.benefitPrice !== undefined) {
      arr.push({
        ...element,
        current_price: element.mainBenefit.benefitPrice,
      });
    } else {
      arr.push({
        ...element,
        current_price: element.real_price,
      });
    }
  }
  return arr;
};
